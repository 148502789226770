/* eslint-disable no-unused-vars */

import {sendRequest} from './net';
import { hoMessage, hoMessageDestroy } from './hoComponent';
import moment from 'moment';

export const ACT_NET_REQUEST_PENDING = 'ACT_NET_REQUEST_PENDING';
export const ACT_NET_REQUEST_SUCCESS = 'ACT_NET_REQUEST_SUCCESS';
export const ACT_NET_REQUEST_FAILED = 'ACT_NET_REQUEST_FAILED';
export const ACT_NET_REQUEST_RESET = 'ACT_NET_REQUEST_RESET';
export const ACT_NET_REQUEST_SET_MSG = 'ACT_NET_REQUEST_SET_MSG';
export const ACT_NET_REQUEST_CLEAR = 'ACT_NET_REQUEST_CLEAR';

// reducer handle
const getNetRequestByRouteCode = (reqList, routeCode) => {
    let result = undefined;
    for (let i in reqList){
        let item = reqList[i];
        if (item.routeCode === routeCode){
            result = item;
            break;
        }
    }
    return result;
}

const netRequestState = [];

export const netRequest_reducer = (state=netRequestState, action) => {
    switch(action.type){
        case ACT_NET_REQUEST_PENDING:
        {
            let nextState = Object.assign([], state);
            let request = getNetRequestByRouteCode(nextState, action.routeCode);
            if (request === undefined){
                nextState.push({status: 1, routeCode: action.routeCode, msg: undefined});
            }
            else{
                request.status = 1;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_SUCCESS:
        {
            let nextState = Object.assign([], state);
            let request = getNetRequestByRouteCode(nextState, action.routeCode);
            if (request === undefined){
                nextState.push({status: 2, routeCode: action.routeCode, msg: action.msg});
            }
            else{
                request.status = 2;
                request.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_FAILED:
        {
            let nextState = Object.assign([], state);
            let request = getNetRequestByRouteCode(nextState, action.routeCode);
            if (request === undefined){
                nextState.push({status: 1, routeCode: action.routeCode, msg: undefined});
            }
            else{
                request.status = 0;
                request.msg = undefined;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_RESET:
        {
            let nextState = Object.assign([], state);
            let request = getNetRequestByRouteCode(nextState, action.routeCode);
            if (request !== undefined){
                request.status = 0;
                request.msg = undefined;
            }
            
            return nextState;
        }

        case ACT_NET_REQUEST_SET_MSG:
        {
            let nextState = Object.assign([], state);
            let request = getNetRequestByRouteCode(nextState, action.routeCode);
            if (request === undefined){
                nextState.push({status: 2, routeCode: action.routeCode, msg: action.msg});
            }
            else{
                request.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_CLEAR:
        {
            let nextState = state.slice();
            const index = nextState?.findIndex(item => item.routeCode === action.routeCode);
            if (index !== -1){
                nextState.splice(index, 1);
            }
            return nextState;
        }

        default:
        return state;
    }
}



// select handle
export const getNetRequestStatus = (state, routeCode) => {
    let status = 0;
    const reqInfo = state.net_request;
    for (let i in reqInfo){
        let item = reqInfo[i];
        if (item.routeCode === routeCode){
            status = item.status;
            break;
        }
    }
    return status;
}

export const getNetRequestMsg = (state, routeCode) => {
    let msg = undefined;
    const reqInfo = state.net_request;
    for (let i in reqInfo){
        let item = reqInfo[i];
        if (item.routeCode === routeCode){
            msg = item.msg;
            break;
        }
    }

    return msg;
}


// actions define
// ACT_NET_REQUEST_PENDING
export const actNetRequestPending = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_PENDING, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SUCCESS
export const actNetRequestSuccess = (routeCode, msg=undefined) => {
    return {
        type: ACT_NET_REQUEST_SUCCESS, 
        routeCode: routeCode, 
        msg: msg
    }
};

// ACT_NET_REQUEST_FAILED
export const actNetRequestFailed = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_FAILED, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_RESET
export const actNetRequestReset = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_RESET, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_CLEAR
export const actNetRequestClear = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_CLEAR, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SET_MSG
export const actNetRequestSetMsg = (routeCode, msg) => {
    return {
        type: ACT_NET_REQUEST_SET_MSG, 
        routeCode: routeCode, 
        msg: msg
    }
};

export const netRequestThunk = (path, msgBody, header, routeCode, successHd=undefined, errorHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    const body = await sendRequest(path, msgBody, header);
    if (body !== undefined){
        // console.log(body, Object.prototype.toString.call(body));
        if (body.status !== 0){
            dispatch(actNetRequestFailed(routeCode));

            if (errorHd !== undefined){
                if (errorHd(dispatch, body.status, body.statusMsg, msgBody, path, header, routeCode)){
                    return;
                }
            }

            if (body.statusMsg !== undefined && body.status !== undefined){
                hoMessage({type: 'error', msg: `操作失败! 错误码: ${body.status} 错误提示: ${body.statusMsg}`, duration: 5});
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                hoMessage({type: 'error', msg: `操作失败! 错误提示: 服务端无响应`, duration: 5});
            }
            else{
                hoMessage({type: 'error', msg: `操作失败! 错误提示: 服务端错误`, duration: 5});
            }
        }else{
            if (successHd !== undefined){
                successHd(dispatch, body, msgBody, path, header, routeCode);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd !== undefined){
            if (errorHd(dispatch, -1, "服务器无响应", msgBody, path, header, routeCode)){
                return;
            }
        }
        hoMessage({type: 'error', msg:`操作失败! 服务器无响应`, duration: 5});
    }
}


export const netRequestThunk2 = (path, msgBody, header, routeCode, successHd=undefined, errorHd=undefined, noauthHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    let start = moment().unix();
    const body = await sendRequest(path, msgBody, header);
    let end = moment().unix();
    if (body !== undefined){
        // console.log(body);
        if (body.Status !== 0){
            dispatch(actNetRequestFailed(routeCode));
            
            // token失效
            if (body.Status === 100008 || body.Status === 100009 || body.status === 401){
                if (noauthHd){
                    noauthHd(dispatch, body, msgBody, path, header, routeCode, end - start, successHd, errorHd);
                    return;
                }
            }

            if (errorHd){
                if (errorHd(dispatch, body.Status, body.StatusMsg, msgBody, path, header, routeCode, end - start)){
                    return;
                }
            }

            hoMessageDestroy();

            if (body.StatusMsg !== undefined && body.Status !== undefined){
                hoMessage({type: 'error', msg: `操作失败! 错误码: ${body.Status} 错误提示: ${body.StatusMsg}`, duration: 5});
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                hoMessage({type: 'error', msg: `操作失败! 错误提示: 服务端无响应`, duration: 5});
            }
            else{
                hoMessage({type: 'error', msg: `操作失败! 错误提示: 服务端错误`, duration: 5});
            }

            if (body.Status === 100006) {
                window.location.href = '/';
            }

        }else{
            if (successHd){
                successHd(dispatch, body, msgBody, path, header, routeCode, end - start);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd){
            if (errorHd(dispatch, -1, "服务器无响应", msgBody, path, header, routeCode, end - start)){
                return;
            }
        }
        hoMessage({type: 'error', msg:`操作失败! 服务器无响应`, duration: 5});
    }
}