import moment from 'moment';
import { Uploader } from "./upload";
import { version } from "./version";

// 日志级别(数字不能变)
export let LEVEL = {
    FATAL: 1,
    ERROR: 2,
    WARN: 3,
    INFO: 4,
    DEBUG: 5,
    TRACE: 6,
}

// 上传组件
let handler = new Uploader();
let file = null;

// 保存原有console打印
console.oldlog = console.log;  
console.oldtrace = console.trace;  
console.olddebug = console.debug;  
console.oldinfo = console.info;  
console.oldwarn = console.warn;  
console.olderror = console.error;  

// 主要输出函数
let log = function() {
    let module = arguments[0];
    let level = arguments[1];
    let description = ' [LOG] ';
    let args = [...arguments].splice(2)
    let fun = console.oldlog;

    switch (level) {
        case LEVEL.TRACE: fun = console.olddebug; description = ' [TRACE] '; break;
        case LEVEL.DEBUG: fun = console.olddebug; description = ' [DEBUG] '; break;
        case LEVEL.INFO: fun = console.oldinfo; description = ' [INFO ] '; break;
        case LEVEL.WARN: fun = console.oldwarn; description = ' [WARN ] '; break;
        case LEVEL.ERROR: fun = console.olderror; description = ' [ERROR] '; break;
        case LEVEL.FATAL: fun = console.olderror; description = ' [FATAL] '; break;
        default: break;
    }
    module += description + moment().format("YYYY-MM-DD HH:mm:ss");
    fun(module, ...args);  
    if (!file) {
        file = handler.addFile()
    }
    file.addLine(level, module, ...args) 
}

// 关闭console打印
if (version.environment === "production") {
    window.console.log = console.log = function() {};  
    window.console.trace = console.trace = function() {};  
    window.console.debug = console.debug = function() {};  
    window.console.info = console.info = function() {}; 
    window.console.warn = console.warn = function() {}; 
    window.console.error = console.error = function() {}; 
}

// 日志类
let Log = {}

// 获取上传的file对象
Log.getFileHandler = function() {
    return file;
}

// 上传文件
Log.uploadFile = function(name, info, userData, suffix, success, error) {
    if (file) {
        file.name = name;
        file.info = info;
        file.userData = userData;
        file.suffix = suffix;
        handler.upload(file, success, error)
        file = null;
    }
}

// trace日志
Log.trace = function() {
    log("[JZ]", LEVEL.TRACE, ...arguments)
}

// debug日志
Log.debug = function() {
    log("[JZ]", LEVEL.DEBUG, ...arguments)
}

// info日志
Log.info = function() {
    log("[JZ]", LEVEL.INFO, ...arguments)
}

// warn日志
Log.warn = function() {
    log("[JZ]", LEVEL.WARN, ...arguments)
}

// error日志
Log.error = function() {
    log("[JZ]", LEVEL.ERROR, ...arguments)
}

// fatal日志
Log.fatal = function() {
    log("[JZ]", LEVEL.FATAL, ...arguments)
}

export { Log };