/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from "react-redux"
import { Tabs, Form, Input } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { version } from '../../util/version';
import { getLoginUserInfo } from '../login/loginRD';

import "./test.less";

@withTranslation('translation', {withRef: true})
class Test extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }
    mkCreate() {
        let { t, params, onChange } = this.props;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        let component = <div className="tabpane-content tabpane-content-create">
            <Form {...layout}>
                <Form.Item label={t("test.tab.create.form.duration")}>
                    <Input
                        onFocus={() => {
                            clearTimeout(this.blurTimer)
                            if (params) {
                                params.focus = true
                                onChange && onChange(params)
                            }
                        }}
                        onBlur={() => {
                            this.blurTimer = setTimeout(() => {
                                if (params) {
                                    params.focus = false
                                    onChange && onChange(params)
                                }
                            }, 200)
                        }}
                        value={params?.duration}
                        onChange={(e) => {
                            if (params) {
                                params.duration = e.target.value
                                onChange && onChange(params)
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label={t("test.tab.create.form.interval")}>
                    <Input
                        onFocus={() => {
                            clearTimeout(this.blurTimer)
                            if (params) {
                                params.focus = true
                                onChange && onChange(params)
                            }
                        }}
                        onBlur={() => {
                            this.blurTimer = setTimeout(() => {
                                if (params) {
                                    params.focus = false
                                    onChange && onChange(params)
                                }
                            }, 200)
                        }}
                        value={params?.interval}
                        onChange={(e) => {
                            if (params) {
                                params.interval = e.target.value
                                onChange && onChange(params)
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </div>
        return component;
    }

    mkJoin() {
        let { t, params, onChange } = this.props;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        let component = <div className="tabpane-content tabpane-content-join">
            <Form {...layout}>
                <Form.Item label={t("test.tab.join.form.code")}>
                    <Input
                        onFocus={() => {
                            clearTimeout(this.blurTimer)
                            if (params) {
                                params.focus = true
                                onChange && onChange(params)
                            }
                        }}
                        onBlur={() => {
                            this.blurTimer = setTimeout(() => {
                                if (params) {
                                    params.focus = false
                                    onChange && onChange(params)
                                }
                            }, 200)
                        }}
                        value={params?.code}
                        onChange={(e) => {
                            if (params) {
                                params.code = e.target.value
                                onChange && onChange(params)
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label={t("test.tab.join.form.duration")}>
                    <Input
                        onFocus={() => {
                            clearTimeout(this.blurTimer)
                            if (params) {
                                params.focus = true
                                onChange && onChange(params)
                            }
                        }}
                        onBlur={() => {
                            this.blurTimer = setTimeout(() => {
                                if (params) {
                                    params.focus = false
                                    onChange && onChange(params)
                                }
                            }, 200)
                        }}
                        value={params?.duration}
                        onChange={(e) => {
                            if (params) {
                                params.duration = e.target.value
                                onChange && onChange(params)
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label={t("test.tab.join.form.interval")}>
                    <Input
                        onFocus={() => {
                            clearTimeout(this.blurTimer)
                            if (params) {
                                params.focus = true
                                onChange && onChange(params)
                            }
                        }}
                        onBlur={() => {
                            this.blurTimer = setTimeout(() => {
                                if (params) {
                                    params.focus = false
                                    onChange && onChange(params)
                                }
                            }, 200)
                        }}
                        value={params?.interval}
                        onChange={(e) => {
                            if (params) {
                                params.interval = e.target.value
                                onChange && onChange(params)
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </div>
        return component;
    }

    mkTabs() {
        let { t, status, startParams, params, onChange } = this.props;

        return <Tabs className="tabs" onChange={(e) => {
            if (params) {
                params.script = e;
                onChange && onChange(params)
            }
        }}> 
            <Tabs.TabPane 
                tab={<span className="tabpane">
                        <span className="title">
                            {(status === "doing" && startParams?.script === "create") ? <LoadingOutlined /> : undefined}
                            {t('test.tab.create.title')}
                        </span>
                    </span>}
                key="create"
            >
                {this.mkCreate()}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <span className="title">
                            {(status === "doing" && startParams?.script === "join") ? <LoadingOutlined /> : undefined}
                            {t('test.tab.join.title')}
                        </span>
                    </span>}
                key="join"
            >
                {this.mkJoin()}
            </Tabs.TabPane>
        </Tabs>
    }

    componentDidMount() {
        if (version.environment !== 'production') {
            window.setting = this;
        }
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        let tabComponent = this.mkTabs();

        return <div className="content">
            {tabComponent}
        </div>
    }
}


let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null,
    null,
)(Test);
