/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from "react-redux"
import { Button, Tabs, Descriptions} from "antd";
import { AppstoreOutlined, BugOutlined } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { adapter } from '../../util/adapter';
import { version } from '../../util/version';
import { Log } from "../../util/log"
import { message } from "../../util/message";
import { uploadInfo } from '../../util/logic';
import { getLoginUserInfo } from '../login/loginRD';
import { printObject } from '../../util/logic';

import "./setting.less";

@withTranslation('translation', {withRef: true})
class Setting extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }
    mkBase(style) {
        let { t, height, width, reqUserInfo } = this.props;

        let component = <div className="tabpane-content tabpane-content-base" style={style}>
            <Descriptions className="descriptions" title={t('setting.base.descriptions.profile.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.profile.name')}>{reqUserInfo?.user?.name}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.profile.sipNum')}>{reqUserInfo?.user?.account}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.profile.token')}>{reqUserInfo?.auth.token}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.feature.window')}>{width} * {height}</Descriptions.Item>
            </Descriptions>

            <Descriptions className="descriptions" title={t('setting.base.descriptions.version.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.version.environment')}>{t(`login.server.${version.environment}`)}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.version.software')}>{version.software}</Descriptions.Item>
            </Descriptions>

            {/* <Descriptions className="descriptions" title={t('setting.base.descriptions.feature.title')} column={1}>
            </Descriptions> */}

        </div>
        return component;
    }

    mkLog(style) {
        let that = this;
        let { uploading } = this.state;
        let { t, reqUserInfo } = this.props;

        let handler = Log.getFileHandler();
        let lines = handler?.lines || [];

        let component = <div className="tabpane-content tabpane-content-log" style={style}>
            <div className="output-row">
                {
                    lines.length > 0 ? lines.map((line, index) => {
                        return <div key={index} className={"line" + line.level}>{line.content}</div>
                    }) : <div>{t(uploading ? 'setting.log.output.uploading' : 'setting.log.output.empty')}</div>
                }
            </div>
            <Button className="upload" type="primary" loading={uploading}  onClick={(e) => {
                e.stopPropagation();
                let { name, info } = uploadInfo(reqUserInfo, version, adapter.explorerInfo)
                that.setState({uploading: true});
                Log.uploadFile(name, info, null, 'log', 
                    (result) => {
                        that.uploadLogTimer = setTimeout(() => {
                            message.success({ content: t('setting.log.upload.message.success') })
                            that.setState({uploading: false});
                        }, 1000)
                    }, (error) => {
                        that.uploadLogTimer = setTimeout(() => {
                            message.success({ content: t('setting.log.upload.message.failed') })
                            that.setState({uploading: false, uploadError: error});
                        }, 1000)
                    }
                );
            }}>{t('setting.log.upload.btn')}</Button>
        </div>

        return component;
    }

    componentDidMount() {
        if (version.environment !== 'production') {
            window.setting = this;
        }
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        let { t } = this.props;
        let component = <Tabs className="tabs" tabPosition="left"> 
            <Tabs.TabPane 
                tab={<span className="tabpane">
                        <AppstoreOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.base')}</span>
                    </span>}
                key="base"
            >
                {this.mkBase()}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <BugOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.log')}</span>
                    </span>}
                key="log"
            >
                {this.mkLog()}
            </Tabs.TabPane>
        </Tabs>

        return <div className="content">
            {component}
        </div>
    }
}


let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null,
    null,
)(Setting);
