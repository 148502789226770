/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import moment from "moment";
import { config } from './version';

class Line {
    constructor(level, content) {
        this.__level = level
        this.__content = content
    }

    get level() {
        return this.__level;
    }

    get content() {
        return this.__content;
    }
}

class File {
    constructor(name, info, userData, suffix = config.upload.suffix) {
        this.__id = this.__timestamp = Date.now();
        this.__name = name;
        this.__info = info;
        this.__userData = userData;
        this.__suffix = suffix;

        this.__lines = [];
    }

    get id() {
        return this.__id;
    }

    set name(value) {
        this.__name = value;
    }

    get name() {
        return this.__name;
    }

    set info(value) {
        this.__info = value;
    }

    get info() {
        return this.__info;
    }

    set userData(value) {
        this.__userData = value;
    }

    get userData () {
        return this.__userData;
    }

    set suffix(value) {
        this.__suffix = value;
    }

    get suffix () {
        return this.__suffix;
    }

    get lines() {
        return this.__lines;
    }

    getLineCnt() {
        return this.__lines.length();
    }

    addLine(level) {
        let c = [...arguments].splice(1).join(' ')
        this.__lines.push(new Line(level, c))
    }

    packet() {
        return {
            name: `${this.__name}_${moment(this.__timestamp).format("YYYY_MM_DD_HH_mm_ss")}.${this.__suffix}`,
            content: [this.__info, "[LOG]\r\n"].concat(this.__lines.map(line => `${line.content}\r\n`)),
        }
    }
}

export class Uploader {
    constructor() {
        let OSS = require('ali-oss');
        this.__ossClient = new OSS({
            region: config.upload.region,
            accessKeyId: config.upload.accessKeyId,
            accessKeySecret: config.upload.accessKeySecret,
            bucket: config.upload.bucket,
        });
        this.__files = {};
    }

    __upload(file, dir, success = undefined, error = undefined) {
        let { name, content } = file.packet();
        let blob = new Blob(content, {type: "text/plain"});
        try {
            this.__ossClient.put(dir + name, blob).then((res) => {
                if (success) {
                    success(res);
                }
            }).catch((e) => {
                if (error) {
                    error(e);
                }
            })
        } catch (e) {
            if (error) {
                error(e);
            }
        }
    }

    addFile(name, info, userData, suffix) {
        let file = new File(name, info, userData, suffix)
        this.__files[file.id] = file
        return file;
    }

    upload(file = null, success = undefined, error = undefined) {

        console.log("uploading...");
        // 确定目录
        let dir = config.upload.dir
        if (dir?.length > 0) {
            if (!dir.endsWith('/')) {
                dir += '/'
            }
        } else {
            dir = "";
        }
        dir += `${moment().format("YYYY-MM-DD")}/`

        // 上传文件
        if (!file) {
            // 上传所有文件
            for (let _id in this.__files) {
                this.__upload(this.__files[_id], dir, success, error)
            }
        } else {
            // 上传指定文件
            this.__upload(file, dir, success, error)
        }

        // 上传完成之后重置
        this.__files = {}
    }
}