
export let ROLE = {
    NONE: 0,
    
    MIAN_MIN: 1,
    MID: 1,
    LEFT: 2,
    RIGHT: 3,
    MIAN_MAX: 3,

    EXTRA_MIN: 100,
    EXTRA1: 100,
    EXTRA2: 101,
    EXTRA3: 102,
    EXTRA4: 103,
    EXTRA5: 104,
    EXTRA6: 105,
    EXTRA_MAX: 105,
    
    list() {
        return [
            { key: this.MID, value: "role.mid" },
            { key: this.LEFT, value: "role.left" },
            { key: this.RIGHT, value: "role.right" },
            { key: this.EXTRA1, value: "role.extra1" },
            { key: this.EXTRA2, value: "role.extra2" },
            { key: this.EXTRA3, value: "role.extra3" },
            { key: this.EXTRA4, value: "role.extra4" },
            { key: this.EXTRA5, value: "role.extra5" },
            { key: this.EXTRA6, value: "role.extra6" },
        ]
    },

    description(role) {
        let e = this.list().find(x => x.key === role);
        return e ? e.value : `unknown`
    }
}
