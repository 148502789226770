/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { printObject, uploadInfo, } from "./logic";
import { Log } from "./log";
import { version } from "./version";

function getExplorerInfo() {
    let explorer = navigator.userAgent.toLowerCase();
    let type = undefined;
    let version = undefined;
    //ie
    if (explorer.indexOf("msie") >= 0) {
        version = explorer.match(/msie ([\d.]+)/)[1];
        type = "IE";
    }
    //firefox
    else if (explorer.indexOf("firefox") >= 0) {
        version = explorer.match(/firefox\/([\d.]+)/)[1];
        type = "Firefox";
    }
    //Chrome
    else if (explorer.indexOf("chrome") >= 0){
        version = explorer.match(/chrome\/([\d.]+)/)[1];
        type = "Chrome";
    }
    //Opera
    else if (explorer.indexOf("opera") >= 0){
        version = explorer.match(/opera.([\d.]+)/)[1];
        type = "Opera";
    }
    //Safari
    else if(explorer.indexOf("Safari") >= 0){
        version = explorer.match(/version\/([\d.]+)/)[1];
        type = "Safari";
    }

    return {
        type: type,
        version: version,
    }
}

class Adapter {
    constructor() {
        this.__explorer = getExplorerInfo();
        this.__isDeviceListSupported = !!(navigator?.mediaDevices?.enumerateDevices);
        this.__isScreenShareSupported = !!(navigator?.mediaDevices?.getDisplayMedia);
    }

    get isDeviceListSupported() {
        return this.__isDeviceListSupported;
    }

    get isScreenShareSupported() {
        return this.__isScreenShareSupported;
    }

    get explorerInfo() {
        return this.__explorer;
    }

    getUserMedia(constraints, success, error) {
        if (navigator.mediaDevices?.getUserMedia) {
            //最新的标准API
            navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
        } else if (navigator.webkitGetUserMedia) {
            //webkit核心浏览器
            navigator.webkitGetUserMedia(constraints, success, error)
        } else if (navigator.mozGetUserMedia) {
            //firfox浏览器
            navigator.mozGetUserMedia(constraints, success, error);
        } else if (navigator.getUserMedia) {
            //旧版API
            navigator.getUserMedia(constraints, success, error);
        }
    }

    getDisplayMedia(constraints, success, error) {
        if (navigator.mediaDevices?.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia(constraints).then(success).catch(error);
        } else {
            if (error) {
                error("not supported");
            }
        }
    }

    requestPermissionNotification(fun) {
        if (window.Notification?.requestPermission) {
            window.Notification.requestPermission(fun);
            return true;
        } else {
            return false;
        }
    }

    getNotification(message, options) {
        if (window.Notification) {
            return new window.Notification(message, options);
        } else {
            return null;
        }
    }

    deviceOnChange(onChange) {
        if (navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = onChange;
        }
    }

    deviceEnumerate(success, error) {
        if (navigator.mediaDevices?.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices().then(success).catch(error);
        } else {
            if (error) {
                error("not supported");
            }
        }
    }

    // 用户全屏，拉伸窗口时触发
    addPageResizeListener() {
        let that = this;
        function windowSizeOnChange() {
            that.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            });
        }
        window.addEventListener("resize", windowSizeOnChange);
        windowSizeOnChange();
    }

    // 用户切换tab页签，或者最小化
    addPageHiddenListener() {
        let that = this;
        function getHiddenProp(){
            let prefixes = ['webkit','moz','ms','o'];
            
            // if 'hidden' is natively supported just return it
            if ('hidden' in document) return 'hidden';
            
            // otherwise loop over all the known prefixes until we find one
            for (let i = 0; i < prefixes.length; i++){
                if ((prefixes[i] + 'Hidden') in document) 
                    return prefixes[i] + 'Hidden';
            }
        
            // otherwise it's not supported
            return null;
        }
        let prop = getHiddenProp();
        let evtName  = prop.replace(/[H|h]idden/,'') + 'visibilitychange';
        that.pageHidden = false;
        document.addEventListener(evtName, function() { 
            that.pageHidden = !prop ? false : document[prop];
        });

        window.onfocus = function (e) {
            that.pageHidden = false;
        }

        window.onblur = function (e) {
            that.pageHidden = true;
        }

    }

    addPageErrorListener() {
        let that = this;
        window.onerror = function (message, scriptUri, lineNo, columnNo, error) {
            try {
                if (message === "ResizeObserver loop limit exceeded") {
                    return;
                }

                Log.fatal(`global error: ${message} ${scriptUri} ${lineNo} ${columnNo}`)

                let { reqUserInfo } = that.props;
                let content = {
                    message: message,
                    scriptUri: scriptUri,
                    lineNo: lineNo,
                    columnNo: columnNo,
                    error: error ? error.toString() : undefined,
                }
                let { name, info } = uploadInfo(reqUserInfo, version, getExplorerInfo());
                info = `[FATAL]${printObject(content)}\r\n` + info;
                Log.uploadFile(`1_fatal_error_${name}`, info, null, 'log');
            } catch (error) {
                Log.error(`catch error: ${error.toString()}`)
            }
        };
    }
}

let adapter = new Adapter();
export { adapter };
