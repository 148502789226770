/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Pagination, Modal, Card, Button, Col, Switch, Empty } from 'antd';
import { LinkOutlined, DisconnectOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { getLoginUserInfo } from '../login/loginRD';

import './cameraList.less'

@withTranslation('translation', {withRef: true})
class CameraList extends Component {
    constructor(props){
        super(props);
        
        this.state = {
        };
    }

    componentDidMount() {

    }


    render() {
        
        let { t, members, visible, cameras, total, page, cancelOnClick, onlyOnline, onlyOnlineOnChange, paginationOnChange, cameraOnClick } = this.props;

        return <Modal
            className="page camera-page"
            title={t('modal.camera_list.title')}
            width="80%"
            visible={visible}
            onCancel={cancelOnClick}
            footer={null}
        >
            <ul className="camera-page-list">
                {
                    cameras ? cameras.map((camera, index) => {
                        // 默认不在线
                        let state = "offline";
                        let stateDesc = t('common.offline');
                        let icon = <DisconnectOutlined style={{ color: "#ff4d4f" }} />;
                        let operations = [];

                        if (camera.AppState === "online") {
                            // 在线
                            state = "online";
                            stateDesc = t('common.online');
                            icon = <LinkOutlined style={{ color: "#73d13d" }} />;
                            operations.push(
                                <Button key="join" className="action-item" type="primary"
                                    onClick={(e) => cameraOnClick && cameraOnClick('join', camera)}>
                                    <UsergroupAddOutlined />{t('modal.camera_list.btn.join')}
                                </Button>
                            )
                            let cameraMember = members?.find(m => m.SipNum === camera.SipNum);
                            switch (cameraMember?.State) {
                                case "joined":
                                    // 已经加入到本会议中
                                    state = "joined";
                                    stateDesc = t('common.joined');
                                    icon = <LinkOutlined style={{ color: "#73d13d" }} />;
                                    operations = [];
                                    operations.push(
                                        <Button key="kick" className="action-item" type="primary"
                                            onClick={(e) => cameraOnClick && cameraOnClick('kick', camera)}>
                                            <UsergroupDeleteOutlined />{t('modal.camera_list.btn.kick')}
                                        </Button>
                                    )
                                    break;
                                case "inviting":
                                    // 正在要求进入本会议
                                    state = "inviting";
                                    stateDesc = t('common.inviting');
                                    icon = <LoadingOutlined style={{ color: "#ffc53d" }} />;
                                    operations = [];
                                    break;
                                case "notjoin":
                                default:
                                    // 未入会或找不到该成员
                                    if (camera.ConfState === "joined") {
                                        // 查看该成员是否在其他会议中
                                        state = "occupy";
                                        stateDesc = t('common.occupy');
                                        icon = <LinkOutlined style={{ color: "#faad14" }} />;
                                        operations = [];
                                    }
                                    break;
                            }
                        }

                        return <li key={index}>
                            <Card className="camera-page-list-card" title={null}>
                                <div className="camera-page-list-card-wrapper">
                                    <div className="camera-page-list-card-wrapper-avatar">
                                        <img alt={camera.NickName} src={camera.AvatarUrl}/>
                                    </div>
                                    <div className="camera-page-list-card-wrapper-title">
                                        <div className="camera-page-list-card-wrapper-title-nickname">{camera.NickName}</div>
                                        <div className="camera-page-list-card-wrapper-title-state">
                                            {icon}
                                            <span className={state}>{stateDesc}</span>
                                        </div>
                                        <div className="camera-page-list-card-wrapper-title-operation">
                                            {operations}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </li>
                    }) : <li className="camera-page-list-li-empty-wrapper">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('modal.camera_list.empty')} />
                    </li>
                }
            </ul>
            <Switch
                checked={onlyOnline}
                onChange={onlyOnlineOnChange}
                checkedChildren={t('modal.camera_list.online_switch.checked')}
                unCheckedChildren={t('modal.camera_list.online_switch.unchecked')}
            />
            {
                cameras?.length > 0 ? <div className="camera-page-pagination">
                    <Pagination
                        current={page?.number || 1} 
                        pageSize={page?.size || 12} 
                        total={total} 
                        defaultCurrent={1}
                        defaultPageSize={12}
                        showTotal={(total, range) => {
                            return <span>{t('modal.camera_list.pagination.show_total', {total: total, range0: range[0], range1: range[1]})}</span>
                        }}
                        pageSizeOptions={[(page?.size || 12).toString()]}
                        onChange={paginationOnChange} 
                        onShowSizeChange={paginationOnChange} />
                </div> : undefined
            }
        </Modal>

    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null,
    null,
)(CameraList);