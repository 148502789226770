
export let TYPE = {
    NORMAL: 0,
    EXTRA6: 1,

    list() {
        return [
            { key: this.NORMAL, value: "type.normal" },
            { key: this.EXTRA6, value: "type.extra6" },
        ]
    },

    description(type) {
        let e = this.list().find(x => x.key === type);
        return e ? e.value : `unknown`
    }
}
