/* eslint-disable no-unused-vars */
let version = {
    software: "v1.1.14-beta11-7695c75d", // ATTENTION: 切勿修改v1.1.14-beta11-7695c75d这个值，在gitlab构建时，会自动替换成对应版本
    environment: process.env.REACT_APP_ENV,
    // environment: "production",
}

let config = {
    host: {
        cm: 'https://ludiqiao.com',
        cs: 'https://ludiqiao.com',
        wss: 'wss://apimntn.ludiqiao.com:8036'
    },

    websocket: {
        heartbeatInterval: 20,  // 单位：秒
    },

    upload: {
        region: 'oss-cn-shenzhen',
        accessKeyId: 'LTAIInySYbttfIvB',
        accessKeySecret: 'LFbM6utT5uEfawWrgaObJbEiteRgN1',
        bucket: 'tv-client-log',
        dir: `jzcontroller/${version.environment}/`, // 上传目录
        suffix: '.log', // 文件后缀名
    },
}

export function switchEnvironment(evn) {
    version.environment = evn;
    switch (version.environment) {
        case "production":
            config.host.cm = 'https://ludiqiao.com';
            config.host.cs = 'https://ludiqiao.com';
            config.host.wss = 'wss://apimntn.ludiqiao.com:8036';
            break;
        case "show":
            config.host.cm = 'https://show.ludiqiao.com';
            config.host.cs = 'https://show.ludiqiao.com';
            config.host.wss = 'wss://show.ludiqiao.com';
            break;
        case "development":
        case "test":
            config.host.cm = 'https://test.ludiqiao.com';
            config.host.cs = 'https://test.ludiqiao.com';
            config.host.wss = 'wss://test.ludiqiao.com';
            break;
        default:
            break;
    }
}

switchEnvironment(version.environment)

export { config, version };