
export let STATE = {
    INVITING: "inviting",
    JOINED: 'joined',
    NOT_JOINED: 'notjoin',
    ONLINE: 'online',
    OFFLINE: 'offline',
    INPRORESS: 'inproress',

    list() {
        return [
            { key: this.INVITING, description: "common.inviting" },
            { key: this.JOINED, description: "common.joined" },
            { key: this.NOT_JOINED, description: "common.notjoin" },
            { key: this.ONLINE, description: "common.online" },
            { key: this.OFFLINE, description: "common.offline" },
            { key: this.INPRORESS, description: "common.inproress" },
        ]
    },

    description(key) {
        let e = this.list().find(x => x.key === key);
        return e ? e.description : `unknown`
    }
}

