/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller';
import { List, Tag, Button, Modal, Card, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next'
import { getLoginUserInfo } from '../login/loginRD';
import { ROLE } from '../../model/role';
import { WINDOW } from '../../model/window';
import { STATE } from '../../model/state';
import { TYPE } from '../../model/type';

import './memberList.less'

@withTranslation('translation', {withRef: true})
class MemberList extends Component {
    constructor(props){
        super(props);

        this.state = {
            curSelectedSipNum: undefined,
            hideWindows: {}
        };
    }

    componentDidMount() {

    }

    getScreenSelect() {
        let { screenSelect } = this.props;

        let role = 0;
        let wind = 0;
        if (screenSelect) {
            let sps = screenSelect.split("-");
            if (sps.length >= 2) {
                role = parseInt(sps[0]);
                wind = parseInt(sps[1]);
            }
        }

        return {
            screenSelectRole: role,
            screenSelectWindow: wind,
        }
    }

    getNewMember() {
        let { jzMember, jzConference, jzConfig, meAndCreatorMember } = this.props;
        let newMembers = [];
        let meAndCreatorMembers = Object.assign([], meAndCreatorMember?.MemberList || []);
        let creator = meAndCreatorMembers.find(m => m.SipNum === jzConference?.ConfInfo?.CreateSipNum);
        let jz = [];
        
        // eslint-disable-next-line no-unused-expressions
        jzConfig?.MemberList?.forEach(m => {
            let member = meAndCreatorMembers.find(m1 => m1.SipNum === m?.SipNum);
            if (member) {
                if (m?.AppState === STATE.OFFLINE) {
                    member.State = STATE.OFFLINE;
                }
                member.NickName = m.NickName;
                member.Role = m.Role;
                jz.push(member)
            }
        })

        if (jz?.length > 0) {
            newMembers.unshift({ type: 'jzSelf', value: jz });
        }

        if (creator && creator?.SipNum !== jzConfig?.MemberList?.[0]?.SipNum) {
            // 创建者不是自己
            if (creator.RcId) {
                // 创建者是个极真组 
                let jz = [];
                // eslint-disable-next-line no-unused-expressions
                creator.RcInfo?.MemberList?.forEach(m => {
                    let member = meAndCreatorMembers?.find(m2 => m2?.SipNum === m?.SipNum);
                    let jzM = member?.RcInfo?.MemberList?.find(m2 => m?.SipNum === m2?.SipNum);
                    if (member) {
                        member.Role = jzM?.Role;
                        member.NickName = jzM?.NickName;
                        // 让主屏在第一个位置
                        if (jzM?.Role === ROLE.MID) {
                            jz.unshift(member);
                        } else {
                            jz.push(member);
                        }
                    }
                })
                newMembers.push({ type: 'jz', value: jz.sort((a, b) => a.Role - b.Role) })
            } else {
                newMembers.push({ type: 'normal', value: creator });
            }
        }

        let normalMembers = [];
        let jzMap = {};
        let members = Object.assign([], jzMember?.MemberList || []);

        // eslint-disable-next-line no-unused-expressions
        members?.forEach(m => {
            if (m?.RcId) {
                let jzM = m?.RcInfo?.MemberList?.find(m2 => m?.SipNum === m2?.SipNum);
                m.Role = jzM?.Role;
                m.NickName = jzM?.NickName;
                if (!jzMap[m?.RcId]) {
                    jzMap[m?.RcId] = [m]
                } else {
                    // 让主屏在第一个位置
                    if (jzM?.Role === ROLE.MID) {
                        jzMap[m?.RcId].unshift(m)
                    } else {
                        jzMap[m?.RcId].push(m)
                    }
                }
            } else {
                if (m?.State === STATE.JOINED) {
                    normalMembers.unshift({ type: 'normal', value: m })
                } else {
                    normalMembers.push({ type: 'normal', value: m })
                }
            }
        })

        for (let key in jzMap) {
            let jz = jzMap[key].sort((a, b) => a.Role - b.Role);
            if (jz?.[0]?.State === STATE.JOINED) {
                normalMembers.unshift({ type: 'jz', value: jz })
            } else {
                normalMembers.push({ type: 'jz', value: jz })
            }
        }

        let online = 0;
        let total = 0;
        function isJoined(m) {
            return m?.State === STATE.JOINED
        }

        newMembers = newMembers.concat(normalMembers)
        newMembers.forEach(item => {
            switch (item.type) {
                case 'jz':
                case 'jzSelf':
                    // eslint-disable-next-line no-unused-expressions
                    item.value?.forEach(i => {
                        if (i.Role < ROLE.EXTRA_MIN) {
                            if (isJoined(i)) {
                                online += 1;
                            }
                            total += 1;
                        }
                    })
                    break;
                case 'normal':
                    if (isJoined(item.value)) {
                        online += 1;
                    }
                    total += 1;
                    break;
                
                default:
                    break;
            }
            
        });

        return {
            newMembers: newMembers,
            online: online,
            total: total,
        }
    }

    renderNormalMember(item, screenSelectRole, screenSelectWindow) {
        let { curSelectedSipNum, curSelectedJzId } = this.state;
        let { t, jzConference, jzConfig, isChairmain, memberOnClick } = this.props;

        let iconCreator = undefined;
        let iconView = undefined;
        let iconMute = undefined;
        let iconDefinition = undefined;
        let iconJoin = undefined;
        let operations = [];
        let joined = item.State === STATE.JOINED;

        // console.log(item)
        if (joined) {
            if (isChairmain) {
                switch (item.DeviceType) {
                    case "CAMERA-28181":
                        // 是个摄像头
                        break;
                
                    default:
                        if (item.Mute) {
                            operations.push(<Button className="button" key="unmute" type="primary" block onClick={(e) => {
                                memberOnClick && memberOnClick("unmute", item)
                            }}>{t('operation.unmute.btn')}</Button>)
                        } else {
                            operations.push(<Button className="button" key="mute" type="primary" block onClick={(e) => {
                                memberOnClick && memberOnClick("mute", item)
                            }}>{t('operation.mute.btn')}</Button>)
                        }
                        break;
                }
                
                
                operations.push(<Button className="button" key="kick" type="primary" block onClick={(e) => {
                    memberOnClick && memberOnClick("kick", item)
                }}>{t('operation.kick.btn')}</Button>)
            }

            switch (item.DeviceType) {
                case "CAMERA-28181":
                    // 是个摄像头
                    if (item.CameraProfile?.SupportPtz) {
                        operations.push(<Button className="button" key="control" type="primary" block onClick={(e) => {
                            memberOnClick && memberOnClick("camera-control", item)
                        }}>{t('operation.camera_control.btn')}</Button>)
                    }

                    if (!isChairmain) {
                        // 不是主席也可以踢摄像头
                        operations.push(<Button className="button" key="kick" type="primary" block onClick={(e) => {
                            memberOnClick && memberOnClick("kick", item)
                        }}>{t('operation.kick.btn')}</Button>)
                    }
                    break;
            
                default:
                    break;
            }
        }

        if (screenSelectRole) {
            let windowDescription = jzConfig?.RcType === TYPE.NORMAL ? t(WINDOW.description(screenSelectWindow), {window: screenSelectWindow || ""}) : ""
            let disable = jzConference?.ConfInfo?.MiracastOwner?.length > 0; // 如果正在投屏，则不能选流
            let content = t("operation.view.btn", { role: t(ROLE.description(screenSelectRole)), window: windowDescription })
            
            switch (item.DeviceType) {
                case "CAMERA-28181":
                    // 是个摄像头
                    if (screenSelectWindow === 0) {
                        operations.push(<Button className="button" key="view" type={disable ? "danger" : "primary"} block onClick={(e) => {
                            memberOnClick && memberOnClick("view", item, {screenSelectRole, screenSelectWindow})
                        }}>{content}</Button>)
                    }
                    break;
            
                default:
                    operations.push(<Button className="button" key="view" type={disable ? "danger" : "primary"} block onClick={(e) => {
                        memberOnClick && memberOnClick("view", item, {screenSelectRole, screenSelectWindow})
                    }}>{content}</Button>)
                    break;
            }
        }

        if (jzConference?.ConfInfo?.CreateSipNum === item.SipNum) {
            iconCreator = <Tag className="icon" color='cyan'>{t('tag.creator')}</Tag>;
        }

        iconMute = <Tag className={"icon " + (item.Mute ? "show" : "hidden")} color='red'>{t('tag.mute')}</Tag>;

        // 不在线，某些状态不显示
        if (!joined) {
            iconMute = undefined;
            iconDefinition = undefined;
            iconJoin = <Tag className="icon" color='red'>{t(item.State === STATE.OFFLINE ? STATE.description(STATE.OFFLINE) : STATE.description(STATE.NOT_JOINED))}</Tag>
        }

        let avatarUrl = item.AvatarUrl?.replace(/http:/, 'https:') || 'https://pingju.oss-cn-shenzhen.aliyuncs.com/DeviceDefaultAvatar2.png';
        return <List.Item
            key={item.SipNum} 
            className="item"
            tabIndex={item.SipNum}
            onClick={(e) => {
                if (joined) {
                    this.setState({
                        curSelectedSipNum: curSelectedSipNum === item.SipNum ? undefined : item.SipNum,
                        curSelectedJzId: operations?.length > 0 ? undefined : curSelectedJzId,
                    })
                }
            }}
        >
            <div className="body">
                <div className="left">
                    <div className="title">
                        <img className={"img "+ (!joined ? "disable" : "enable")} alt={""} src={avatarUrl}/>
                        <span className={"name " + (!joined ? "disable" : "enable")}>
                            {item.NickName}
                        </span>
                    </div>
                    <div className="icons">
                        { iconCreator }
                        { iconDefinition }
                        { iconView }
                        { iconMute }
                        { iconJoin }
                    </div>
                </div>
                <div className={"operation " + ((curSelectedSipNum === item.SipNum && joined) ? "show" : "hidden")} >
                    {operations}
                </div>
            </div>
        </List.Item>
    }

    renderJzMember(items, screenSelectRole, screenSelectWindow, jzSelf) {
        let { curSelectedSipNum, curSelectedJzId, hideWindows } = this.state;
        let { t, jzConference, isChairmain, memberOnClick, jzConfig } = this.props;
        if (items?.length > 0) {
            let mid = items[0];
            let joined = mid.State === STATE.JOINED;
            let iconMe = jzSelf ? <Tag className="icon" color={'blue'}>{t('tag.me')}</Tag> : undefined
            let iconCreator = jzConference?.ConfInfo?.CreateSipNum === mid.SipNum ? <Tag className="icon" color='cyan'>{t('tag.creator')}</Tag> : undefined;
            let jzSelect = items.find(m => m.SipNum === curSelectedSipNum);
            let iconJoin = undefined;
            let operations = [];
            if (joined) {
                if (jzConfig?.RcType === TYPE.NORMAL) {
                    operations.push(<Button className="button" key="group-view" type="primary" block onClick={(e) => {
                        memberOnClick && memberOnClick(jzSelf ? "group-view-self" : "group-view", items)
                        this.setState({
                            curSelectedJzId: undefined,
                            curSelectedSipNum: undefined,
                        })
                    }}>{t(jzSelf ? 'operation.self_view.btn' : 'operation.group_view.btn')}</Button>)
                } else if (jzConfig?.RcType === TYPE.EXTRA6 && jzSelf) {
                    operations.push(<Button className="button" key="group-view" type="primary" block onClick={(e) => {
                        memberOnClick && memberOnClick("group-view-self", items)
                        this.setState({
                            curSelectedJzId: undefined,
                            curSelectedSipNum: undefined,
                        })
                    }}>{t('operation.self_view.btn')}</Button>)
                } else if (jzConfig?.RcType === TYPE.EXTRA6 && !jzSelf && screenSelectRole) {
                    operations.push(<Button className="button" key="group-view" type="primary" block onClick={(e) => {
                        memberOnClick && memberOnClick("group-view", items)
                        this.setState({
                            curSelectedJzId: undefined,
                            curSelectedSipNum: undefined,
                        })
                    }}>{t('operation.group_view.btn')}</Button>)
                }
                
                if (!jzSelf && isChairmain) {
                    operations.push(<Button className="button" key="kick" type="primary" block onClick={(e) => {
                        memberOnClick && memberOnClick("group-kick", mid)
                        this.setState({
                            curSelectedJzId: undefined,
                            curSelectedSipNum: undefined,
                        })
                    }}>{t('operation.kick.btn')}</Button>)
                }
            }
            if (!joined) {
                iconJoin = <Tag className="icon" color='red'>{t(mid.State === STATE.OFFLINE ? STATE.description(STATE.OFFLINE) : STATE.description(STATE.NOT_JOINED))}</Tag>
            }
            return <List.Item className="item" key={mid.SipNum} tabIndex={mid.SipNum}>
                <div className={"body " + (jzSelect ? "jz-body" : "")}>
                    <div className="left" onClick={(e) => {
                        if (joined) {
                            this.setState({
                                curSelectedJzId: curSelectedJzId === mid.RcId ? undefined : mid.RcId,
                                curSelectedSipNum: undefined,
                            })
                        }
                    }}>
                        <div className="title">
                            <img className={"img "+ (!joined ? "disable" : "enable")} alt={""} src={'https://pingju.oss-cn-shenzhen.aliyuncs.com/jz.png'}/>
                            <span className={"name " + (!joined ? "disable" : "enable")}>
                                {mid.RcInfo?.Name}
                            </span>
                        </div>
                        <div className="icons">
                            {iconMe}
                            {iconCreator}
                            {iconJoin}
                            {<Tag className="icon" color='purple'>{t('tag.jz')}</Tag>}
                        </div>
                    </div>
                    <div className={"jz-chilren " + (curSelectedJzId === mid.RcId ? "show" : "hidden")} >
                        {
                            items?.map((item, index) => {
                                let iconMe = <Tag className="icon" color='purple'>{t(ROLE.description(item.Role))}</Tag>
                                let iconView = undefined;
                                let iconMute = undefined;
                                let iconDefinition = undefined;
                                let iconJoin = undefined;
                                let operations = [];
                                // console.log(item)
                                if (item.Role >= ROLE.EXTRA_MIN) {
                                    return undefined;
                                }
                                let joined = item.State === STATE.JOINED;

                                if (joined) {
                                    if (isChairmain || jzSelf) {
                                        if (item.Mute) {
                                            operations.push(<Button className="button" key="unmute" type="primary" block onClick={(e) => {
                                                memberOnClick && memberOnClick("unmute", item)
                                            }}>{t('operation.unmute.btn')}</Button>)
                                        } else {
                                            operations.push(<Button className="button" key="mute" type="primary" block onClick={(e) => {
                                                memberOnClick && memberOnClick("mute", item)
                                            }}>{t('operation.mute.btn')}</Button>)
                                        }

                                        operations.push(<Button className="button" key="show_hidden" type="primary" block onClick={(e) => {
                                            memberOnClick && memberOnClick("show_hidden", item, { hide: !hideWindows[item.SipNum] }, (ret) => {
                                                if (ret) {
                                                    hideWindows[item.SipNum] = !hideWindows[item.SipNum]
                                                    this.setState({
                                                        hideWindows: hideWindows,
                                                    })
                                                }
                                            })
                                            
                                        }}>{t(hideWindows[item.SipNum] ? 'operation.show_window.btn' : 'operation.hide_window.btn')}</Button>)
                                        
                                        // operations.push(<Button className="button" key="kick" type="primary" block onClick={(e) => {
                                        //     memberOnClick && memberOnClick("kick", item)
                                        // }}>踢出</Button>)
                                    }
                                } 

                                if (screenSelectRole) {
                                    let windowDescription = jzConfig?.RcType === TYPE.NORMAL ? t(WINDOW.description(screenSelectWindow), {window: screenSelectWindow || ""}) : ""
                                    if (screenSelectWindow === WINDOW.MAIN || !jzSelf) {
                                        let disable = jzConference?.ConfInfo?.MiracastOwner?.length > 0; // 如果正在投屏，则不能选流
                                        let content = !jzSelf ? t("operation.view.btn", {role: t(ROLE.description(screenSelectRole)), window: windowDescription}) : t("operation.self_view.btn")
                                        operations.push(<Button className="button" key="view" type={disable ? "danger" : "primary"} block onClick={(e) => {
                                            memberOnClick && memberOnClick("view", item, { screenSelectRole, screenSelectWindow })
                                        }}>{content}</Button>)
                                    }
                                }

                                if (jzConference?.ConfInfo?.CreateSipNum === item.SipNum) {
                                    iconCreator = <Tag className="icon" color='cyan'>{t('tag.creator')}</Tag>;
                                }

                                iconMute = <Tag className={"icon " + (item.Mute ? "show" : "hidden")} color='red'>{t('tag.mute')}</Tag>;

                                // 不在线，某些状态不显示
                                if (!joined) {
                                    iconMute = undefined;
                                    iconDefinition = undefined;
                                    if (item.State === STATE.OFFLINE) {
                                        iconJoin = <Tag className="icon" color='red'>{t(STATE.description(STATE.OFFLINE))}</Tag>
                                    } else {
                                        iconJoin = <Tag className="icon" color='red'>{t(STATE.description(STATE.NOT_JOINED))}</Tag>
                                    }

                                    operations = [];
                                    operations.push(<Button className="button" key="join" type="primary" block onClick={(e) => {
                                        memberOnClick && memberOnClick("join", item)
                                    }}>{t('operation.join.btn')}</Button>)
                                }

                                let avatarUrl = item.AvatarUrl?.replace(/http:/, 'https:') || 'https://pingju.oss-cn-shenzhen.aliyuncs.com/DeviceDefaultAvatar2.png';
                                return <div key={index} className="body" onClick={(e) => {
                                        if (joined || jzSelf) {
                                            this.setState({
                                                curSelectedSipNum: curSelectedSipNum === item.SipNum ? undefined : item.SipNum,
                                            })
                                        }
                                    }}>
                                    <div className="left">
                                        <div className="title">
                                            <img className={"img "+ (!joined ? "disable" : "enable")} alt={""} src={avatarUrl}/>
                                            <span className={"name " + (!joined ? "disable" : "enable")}>
                                                {item.NickName}
                                            </span>
                                        </div>
                                        <div className="icons">
                                            { iconMe }
                                            { iconDefinition }
                                            { iconView }
                                            { iconMute }
                                            { iconJoin }
                                        </div>
                                    </div>
                                    <div className={"operation " + (curSelectedSipNum === item.SipNum ? "show" : "hidden")} >
                                        {operations}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={"operation " + (curSelectedJzId === mid.RcId ? "show" : "hidden")} >
                        {operations}
                    </div>
                </div>
            </List.Item>
        }

        return <div></div>;
    }

    mkList() {
        let { t } = this.props;
        
        let { screenSelectRole, screenSelectWindow } = this.getScreenSelect();
        let { newMembers, online, total } = this.getNewMember();
        let height = document?.getElementById("base")?.clientHeight || 100;

        return <Fragment>
            <div className="header">
                <div className="title">
                    <div className="label">{t('member_list.title.label')}</div>
                    <div className="summary">{t('member_list.title.summary', {online: online, total: total})}</div>
                </div>
            </div>
            <div className="infinite"
                style={{height: height - (16 + 60)}}
                >
                <InfiniteScroll
                    initialLoad={true}
                    pageStart={0}
                    loadMore={() => {}}
                    hasMore={false}
                    useWindow={false}
                    >
                    <List
                        className="list"
                        style={{height: height - (16 + 60 + 1)}}
                        dataSource={newMembers}
                        rowKey="SipNum"
                        renderItem={(item, index) => {
                            let jzSelf = false;
                            if (item) {
                                switch (item?.type) {
                                    case "normal":
                                        return this.renderNormalMember(item?.value, screenSelectRole, screenSelectWindow)
                                    case "jzSelf":
                                        jzSelf = true;
                                    // eslint-disable-next-line no-fallthrough
                                    case "jz":
                                        return this.renderJzMember(item?.value, screenSelectRole, screenSelectWindow, jzSelf)
                                    default:
                                        break;
                                }
                            }
                            return <div></div>;
                        }}
                    />
                </InfiniteScroll>
            </div>
        </Fragment>
    }

    render() {

        let listComponent = this.mkList();

        return <div>
            {listComponent}
        </div>;
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null,
    null,
)(MemberList);