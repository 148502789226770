
export let WINDOW = {
    MAIN: 0,
    SECONDARY_1: 1,
    SECONDARY_2: 2,
    SECONDARY_3: 3,
    SECONDARY_4: 4,

    description(w) {
        return (w === WINDOW.MAIN && 'view.window.main') || 'view.window.secondary'
    }
}
