/* eslint-disable no-unused-vars */
import { createFromIconfontCN } from '@ant-design/icons';
import { message} from 'antd';

// message
message.config({
    maxCount: 3,
    duration: 5,
})
export function hoMessage(props){
    let { type, msg, duration, onClose } = props;
    let fun = undefined;
    switch(type){
        case "info": fun = message.info; break;
        case "error": fun = message.error; break;
        case "warning": fun = message.warning; break;
        case "loading": fun = message.loading; break;
        case "success":
        default: fun = message.success; break;
    }
    fun(msg, duration, onClose);
}
hoMessage.defaultProps = {
    type: 'success',
    msg: "",
    duration: 3,
    onClose: undefined,
}

export function hoMessageDestroy() {
    message.destroy();
}

export function HoFontIcon(url=undefined){
    url = url || '//at.alicdn.com/t/font_1256576_o7bc6ty0i0f.js'
    return createFromIconfontCN({
        scriptUrl: url, // 在 iconfont.cn 上生成
    });
}