/* eslint-disable no-unused-vars */

// 初始化分页信息
export const hoTableInitPaginationInfo = (reqBody={}, total=undefined, extra={}) => {
    return {
        pagination:{
            page: {
                number: ((reqBody && reqBody.pagination && reqBody.pagination.page) && reqBody.pagination.page.number) || 1,
                size: ((reqBody && reqBody.pagination && reqBody.pagination.page) && reqBody.pagination.page.size) || 10,
            },
            sort: {
                field: ((reqBody && reqBody.pagination && reqBody.pagination.sort) && reqBody.pagination.sort.field) || undefined,
                order: ((reqBody && reqBody.pagination && reqBody.pagination.sort) && reqBody.pagination.sort.order) || 0,
            },
            filters: ((reqBody && reqBody.pagination) && reqBody.pagination.filters) || [],
            total: total,
            ...extra,
        }
        
    }
}

export const hoTableTransPaginationInfo = (paginationInfo) => {
//      PageSize	每页返回数据条数	是	[int]		查看
//      PageNum	页码数，从1开始	是	[int]		
//      Filters	二维数组，行之间为或（or）关系，列之间为与(and)关系		[array]	[ [{"Key": "NickName", "Type": "str", "Op": "like", "Value": "性能"}] ]	查看
//      OrderKey	以该字段排序， 支持排序字段同Filters中支持过滤的字段列表		[string]		
//      Desc
    let newPag = {};
    newPag['PageSize'] = paginationInfo.pagination.page.size;
    newPag['PageNum'] = paginationInfo.pagination.page.number;
    newPag['Filters'] = []
    for (let orItem of (paginationInfo?.pagination?.filters || [])) {
        let orItems = [];
        for (let andItem of (orItem || [])) {
            if (andItem?.length >= 3) {
                orItems.push({
                    Key: andItem[0],
                    Type: (andItem[1] === "like" || andItem[1] === "equal" || andItem[1] === "not equal") ? "str" : "int",
                    Op: (andItem[1] === "equal" && "=") || (andItem[1] === "not equal" && "!=") || andItem[1],
                    Value: andItem[2],
                })
            }
        }
        if (orItems.length > 0) {
            newPag['Filters'].push(orItems)
        }
    }
    if (paginationInfo.pagination.sort && paginationInfo.pagination.sort.field !== undefined) {
        newPag['OrderKey'] = paginationInfo.pagination.sort.field;
        newPag['Desc'] = paginationInfo.pagination.sort.order;
    }
    return newPag;
}
