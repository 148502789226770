/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Layout, Row, Col, Modal, Input, Button } from 'antd';
import { ExportOutlined, ToolOutlined, BuildOutlined, WarningOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { message } from "../util/message";

import { actNetRequestClear } from '../util/netReqRD';
import { getLoginUserInfo, actLoginClear } from '../pages/login/loginRD';
import { adapter } from '../util/adapter';
import { version } from '../util/version';

import MainRoute from '../router/mainRoute'
import Setting from "../pages/setting/setting";
import Test from "../pages/test/test";

import {
    apiJzConference, apiJzConferenceCreate, apiJzConferenceEnd, apiJzConferenceJoin, apiJzConferenceExit, apiAutoTestAuth, apiJzLogout
} from '../api/api';

import './layout.less';

@withTranslation('translation', {withRef: true})
class MainLayout extends Component {

    constructor(props){
        super(props);

        this.testStart = this.testStart.bind(this);
        this.testStop = this.testStop.bind(this);

        this.menuOnChange = this.menuOnChange.bind(this);
        this.mkHeaderLeft = this.mkHeaderLeft.bind(this);
        this.mkHeaderRight = this.mkHeaderRight.bind(this);

        this.state = {
            settingVisible: false,
            testVisible: false,
            testPassword: undefined,
            testEnable: false,
            testStatus: '',
            testParams: {
                script: 'create',
                duration: 10,
                interval: 1,
                code: '',
                focus: false,
            },
        }
    }

    menuOnChange(keyOrUrl = undefined, urlParam = "", state = undefined, inner = true, blank = false) {
        let {history} = this.props;
        if (!keyOrUrl) {
            history.push('/')
            return true;
        }

        if (inner) {
            if (keyOrUrl.startsWith("/")){
                keyOrUrl = keyOrUrl.substr(1)
            } 
            if (keyOrUrl.endsWith("/")) {
                keyOrUrl = keyOrUrl.substr(0, keyOrUrl.length - 1)
            }
            if (urlParam.startsWith("/")) {
                urlParam = urlParam.substr(1)
            }
            let path = `/conference/${keyOrUrl}/${urlParam}`
            if (!blank) { 
                history.push({
                    pathname: path,
                    state: state
                })
                this.setState({
                    selectedKeys: [keyOrUrl],
                })
            } else {
                window.open(path, "_blank");
            }
        } else {
            if (!blank) {
                window.open(keyOrUrl, "_self")
            } else {
                window.open(keyOrUrl, "_blank")
            }
        }
    }

    testStart() {
        let { testStatus, testParams } = this.state;
        if (testStatus !== "doing") {
            return;
        }

        function successHd(_, rsp, req) {
            function loop(retry) {
                if (retry > 0) {
                    // 还可以继续看会议创起来没
                    this.testLoopTimer = setTimeout(() => {
                        if (this.state.testStatus !== "doing") {
                            return;
                        }

                        apiJzConference(this.props,
                            (_, rsp1, req1) => {
                                if (this.state.testStatus !== "doing") {
                                    return;
                                }

                                if (!rsp1?.ConfInfo?.ConferenceId) {
                                    loop.bind(this)(retry - 1)
                                } else {
                                    this.testConf = rsp1;
                                    let duration = 10;
                                    try {
                                        duration = parseInt(testParams?.duration);
                                    } catch (error) {
                                        
                                    }
                                    this.testDurationTimer = setTimeout(() => {
                                        apiJzConferenceExit(this.props, rsp1?.ConfInfo?.SipCode,
                                            () => {
                                                this.testConf = null;
                                                let interval = 1;
                                                try {
                                                    interval = parseInt(testParams?.interval);
                                                } catch (error) {
                                                    
                                                }
                                                setTimeout(() => {
                                                    if (this.state.testStatus === "doing") {
                                                        this.testStart()
                                                    }
                                                }, interval * 1000);
                                            }
                                        )
                                    }, duration * 1000)
                                }
                            }
                        )
                        
                    }, 1000)
                } else {
                    // 10秒都没有把会议创起来，宣告失败，继续下一个任务
                    if (this.state.testStatus === "doing") {
                        this.testStart()
                    }
                }
                
            }
            if (this.state.testStatus === "doing") {
                loop.bind(this)(10)
            }
        }


        switch (testParams?.script) {
            case "create":
                apiJzConferenceCreate(this.props, `极真控制器测试会议_${Math.floor(Math.random() * 100000)}`, successHd.bind(this))
                break;
            
            case "join":
                let confCode = testParams?.code?.length > 0 ? `${testParams?.code}`.replace(/\D/g, '') : "0"
                apiJzConferenceJoin(this.props, confCode, undefined, successHd.bind(this))
                break;
            
            default:
                message.error({content: "test.message.not_support_script"})
                break;
        }
    }

    testStop() {
        if (this.testLoopTimer) {
            clearTimeout(this.testLoopTimer);
            this.testLoopTimer = 0;
        }
        if (this.testDurationTimer) {
            clearTimeout(this.testDurationTimer);
            this.testDurationTimer = 0;
        }
        if (this.testConf) {
            apiJzConferenceExit(this.props, this.testConf?.ConfInfo?.SipCode,
                () => {
                    this.testConf = null;
                }
            )
        }
    }

    mkSetting() {
        let { t } = this.props;
        let { settingVisible, height, width } = this.state;

        return <Modal
            className="setting"
            width={"50%"}
            title={<span className="title">
                <ToolOutlined className="icon"/>
                <div className="description">{t('setting.title')}</div>
            </span>}
            closable={true}
            maskClosable={true}
            visible={settingVisible}
            onCancel={(e) => {this.setState({settingVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <Setting
                height={height}
                width={width}
            />
        </Modal>
    }

    mkTestPassword() {
        let { t } = this.props;
        let { testPasswordVisible, testPassword } = this.state;

        function ok() {
            apiAutoTestAuth(this.props, testPassword,
                () => {
                    this.setState({
                        testPasswordVisible: false,
                        testEnable: true,
                        testVisible: true,
                    })
                },
                () => {
                    message.error({ content: t('test_password.message.password_error') })
                    return true;
                }
            )
        }

        return <Modal
            className="test-password-modal"
            width={"50%"}
            title={t('test_password.title')}
            okText={t('test_password.btn.ok')}
            cancelText={t('test_password.btn.cancel')}
            closable={true}
            maskClosable={true}
            visible={testPasswordVisible}
            onCancel={(e) => {
                this.setState({ testPasswordVisible: false })
            }}
            onOk={ok.bind(this)}
        >
            <Input
                autoFocus
                value={testPassword}
                placeholder={t('test_password.input.placeholder')}
                onPressEnter={ok.bind(this)}
                onChange={(e) => {
                this.setState({
                    testPassword: e.target.value,
                })
            }}/>
        </Modal>
    }

    mkTest() {
        let { t } = this.props;
        let { testVisible, testStatus, testStartParams, testParams } = this.state;

        let buttons = [];
        switch (testStatus) {
            case "doing":
                buttons.push(<Button key="stop" type="danger" onClick={() => {
                    this.setState({
                        testStatus: "done",
                        testStartParams: {},
                    }, () => {
                        this.testStop()
                    })
                }}>{t('test.btn.stop')}</Button>)
                break;
                
            default:
                buttons.push(<Button key="start" type="primary" onClick={() => {
                    this.setState({
                        testStatus: "doing",
                        testStartParams: Object.assign({}, testParams),
                    }, () => {
                        this.testStart()
                    })
                }}>{t('test.btn.start')}</Button>)
                break;
        }

        return <Modal
            className={"test-modal " + (testParams?.focus ? "top" : "")}
            width={"50%"}
            title={<span className="title">
                <BuildOutlined className="icon"/>
                <div className="description">{t('test.title')}</div>
            </span>}
            closable={true}
            maskClosable={true}
            visible={testVisible}
            onCancel={(e) => {
                this.setState({
                    testVisible: false
                })
            }}
            footer={<div className="btns">
                {buttons}
            </div>}
            destroyOnClose={true}
        >
            <Test
                status={testStatus}
                startParams={testStartParams}
                params={testParams}
                onChange={(params) => {
                    this.setState({
                        testParams: params
                    })
                }}
            />
        </Modal>
    }

    mkHeaderLeft() {
        let { testEnable, testStatus } = this.state;
        let { t } = this.props;
        return <div className="left">
            <div className="name" id="name" onClick={() => {
                if (version.environment !== "production") {
                    if (!testEnable) {
                        this.setState({
                            testPasswordVisible: true,
                            testPassword: undefined,
                        })
                    } else if (testStatus !== "doing") {
                        this.setState({
                            testEnable: false,
                        })
                    } else {
                        message.error({content: t('test.message.close_but_test_doing')})
                    }
                }
            }} />
            <div className="status" id="status">
                <WarningOutlined />
                <span>{t('common.multi_login')}</span>
            </div>
        </div>
    }

    mkHeaderRight() {
        let { testStatus, testEnable } = this.state;
        let { t } = this.props;
        return <div className="right">
            {
                testEnable ? <span className={"index-action test-status-" + testStatus} onClick={() => {
                    this.setState({
                        testVisible: true
                    })
                }}>
                    <BuildOutlined className="icon"/>
                    <div className="description">
                        {/* <span>{testStatus === "doing" ? <LoadingOutlined /> : undefined}</span> */}
                        <span>{t('test.title')}</span>
                    </div>
                </span> : undefined
            }
            <span className="index-action" onClick={() => {
                this.setState({
                    settingVisible: true
                })
            }}>
                <ToolOutlined className="icon"/>
                <div className="description">{t('setting.title')}</div>
            </span>
            <span className="index-action" onClick={() => {
                const { dispatch } = this.props;
                apiJzLogout(this.props, () => {}, () => {return true});
                dispatch(actNetRequestClear());
                dispatch(actLoginClear());
                message.destroy();
                window.goToMenu();
            }}>
                <ExportOutlined className="icon"/>
                <div className="description">{t('exit.title')}</div>
            </span>
        </div>
    }

    componentDidMount() {
        window.goToMenu = this.menuOnChange;

        // 用户全屏，拉伸窗口时触发
        adapter.addPageResizeListener.bind(this)();
    }

    render() {
        let { t } = this.props;
        let headerLeftComponent = this.mkHeaderLeft();
        let headerRightComponent = this.mkHeaderRight();
        let settingComponent = this.mkSetting();
        
        let testPasswordComponent = this.mkTestPassword();
        let testComponent = this.mkTest();
        
        return (
            <Layout className="ho-layout">
                {/* 顶端头部 */}
                <Layout.Header className="ho-header">
                    <Row>
                        <Col span={16}>
                            {headerLeftComponent}
                        </Col>
                        <Col span={8}>
                            {headerRightComponent}
                        </Col>
                    </Row>
                </Layout.Header>
                {/* 主要内容 */}
                <Layout.Content className="ho-content">
                    {testPasswordComponent}
                    {testComponent}
                    {settingComponent}
                    <MainRoute {...this.props}/>
                </Layout.Content>
            </Layout>
        );
    }
}

MainLayout.defaultProps = {
    theme: "light", // 'light' or 'dark'
}
const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null
)(MainLayout);

