/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Modal, Slider, Button, Col, Row } from 'antd';
import { CaretUpOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined, LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { getLoginUserInfo } from '../login/loginRD';

import './cameraControl.less'

@withTranslation('translation', {withRef: true})
class CameraControl extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            speed: 4,
        };
    }

    componentDidMount() {

    }


    render() {
        let { speed } = this.state;
        let { t, visible, cancelOnClick, camera, cameraOnClick } = this.props;

        function control(Icon, className, dir) {
            return <div>
                <Icon className={"action-item " + className}
                    onMouseDown={() => cameraOnClick && cameraOnClick("start", dir, speed)}
                    onTouchStart={() => cameraOnClick && cameraOnClick("start", dir, speed)}
                    onMouseUp={() => cameraOnClick && cameraOnClick("stop")}
                    onTouchEnd={() => cameraOnClick && cameraOnClick("stop")}
                />
            </div>
        }

        return <Modal
            className="page camera-control-page"
            title={`${t('modal.camera_control.title')} - ${camera?.NickName}`}
            width="50%"
            visible={visible}
            onCancel={cancelOnClick}
            footer={null}
        >
            <div className="camera-control-page-content">
                <div className="camera-control-page-content-direction">
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left-up", "leftUp")}
                        {control(CaretUpOutlined, "up", "up")}
                        {control(CaretUpOutlined, "right-up", "rightUp")}
                    </div>
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left", "left")}
                        {control(RedoOutlined, "reset", "reset")}
                        {control(CaretUpOutlined, "right", "right")}
                    </div>
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left-down", "leftDown")}
                        {control(CaretUpOutlined, "down", "down")}
                        {control(CaretUpOutlined, "right-down", "rightDown")}
                    </div>
                </div>
                <div className="camera-control-page-content-control">
                    <div className="camera-control-page-content-control-wrapper">
                        {control(ZoomInOutlined, "zoom-in", "zoomIn")}
                        {control(ZoomOutOutlined, "zoom-out", "zoomOut")}
                    </div>
                    <div className="camera-control-page-content-control-speed">
                        <div className="camera-control-page-content-control-speed-title">{t('modal.camera_control.speed.title')}</div>
                        <Slider value={speed} tipFormatter={null} max={7} min={1}
                            marks={{
                                1: '1',
                                2: '2',
                                3: '3',
                                4: {
                                    style: { color: '#1890ff' },
                                    label: <strong>4</strong>,
                                },
                                5: '5',
                                6: '6',
                                7: '7',
                            }}
                            onChange={(e) => {
                                this.setState({
                                    speed: e
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            
        </Modal>

    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});

export default connect(
    mapState, 
    null,
    null,
)(CameraControl);