/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Card, Descriptions, Button, Tag, Modal, Input, Tabs, Empty, Radio, Icon } from 'antd';
import {
    DisconnectOutlined, ExclamationCircleOutlined, AudioOutlined, AudioMutedOutlined, EyeInvisibleOutlined, EyeOutlined,
    ReloadOutlined, RocketOutlined, UsergroupAddOutlined, LoadingOutlined, LogoutOutlined, AlertOutlined, InstagramOutlined,
    LinkOutlined, SearchOutlined, PoweroffOutlined
} from '@ant-design/icons';
import { config, version } from '../../util/version';
import { message } from "../../util/message";
import { withTranslation } from 'react-i18next'

import { hoTableInitPaginationInfo } from '../../util/tableComponent';
import { getLoginUserInfo } from '../login/loginRD';
import MemberList from '../member/memberList';
import CameraList from '../camera/cameraList';
import CameraControl from '../camera/cameraControl';
import {
    apiJzInfo, apiJzConference, apiJzConferenceMembers, apiJzConferenceCreate, apiJzConferenceCreator,
    apiJzConferenceJoin, apiJzConferenceExit, apiJzConferenceEnd, apiJzConferenceGlobalMute, apiJzMemberMute, apiJzMemberView, apiJzMemberShowOrHiddenWindow,
    apiCameraList, apiMemberJoin, apiMemberKick, apiCameraContrlStart, apiCameraContrlStop, apiScreenShareAgree, apiScreenShareDisagree, apiScreenShareCodeResert,
    apiCameraPositionSet, apiCameraModeSet, apiShutdown
} from '../../api/api';

import { ROLE } from '../../model/role';
import { WINDOW } from '../../model/window';
import { TYPE } from '../../model/type';
import { STATE } from '../../model/state';

import { adapter } from '../../util/adapter';
import { Log } from '../../util/log';
import { printObject } from '../../util/logic';

import './main.less';

@withTranslation('translation', {withRef: true})
class MainCT extends Component {

    constructor(props){
        super(props);

        this.state = {
            flag: false,
            paginationInfo: hoTableInitPaginationInfo({ pagination: { sort: { field: 'DeviceType', order: 1 }}}),

            jzConfig: undefined,
            jzConference: undefined,
            jzMember: undefined,
            meAndCreatorMember: undefined,

            confTitle: undefined,
            createPageVisible: false,

            confCode: undefined,
            joinPageVisible: false,

            screenSelect: undefined,
            hideWindow: false,

            // 摄像头列表
            cameras: [],
            cameraOnlyOnline: true,
            cameraPageVisible: false,
            cameraPaginationInfo: hoTableInitPaginationInfo({ pagination: { page: { size: 12 } } }),
            
            // 摄像头控制
            camera: undefined,
            cameraControlPageVisible: false,
        };

        ROLE.list().forEach(e => {
            this.state[`preview-${e.key}`] = false;
        });
    }

    codeFormatter(value) {
        return value ? `${value}`.replace(/\D/g, '').replace(/(\d{3})(?=(\d)+(?!\d))/g, '$1 ') : ""
    }

    screenShareCodeFormatter(value) {
        return value ? `${value}`.replace(/\D/g, '').replace(/(\d{4})(?=(\d)+(?!\d))/g, '$1 ') : ""
    }

    codeParser(value) {
        return value ? `${value}`.replace(/\D/g, '') : ""
    }

    codeFix(code) {
        return ("00000000" + code).slice(-9)
    }

    initConference() {
        this.joinMemberTimer = {};
        this.opertionViewRecords = {};
        this.messageDebounceTimer = 0;
    }

    initWebSocket() {

        let { reqUserInfo } = this.props;
        if (reqUserInfo?.auth?.token) {
            Log.debug(`websocket init. token:${reqUserInfo?.auth?.token} cnt:${this.websocketRestartCnt}`);

            let url = config.host.wss + `/cs/v1/app/ws/reallyClient/?Token=${reqUserInfo?.auth?.token}`;
            let ws = new WebSocket(url);
            this.websocket = ws;
            this.websocketRestartCnt++;

            ws.onopen = (e) => this.wsOnOpen(e);
            ws.onmessage = (e) => this.wsOnMessage(e);
            ws.onclose = (e) => this.wsOnClose(e);
        }
    }

    uninitWebSocket() {
        Log.debug("websocket uninit");

        if (this.wsHbTimer) {
            clearInterval(this.wsHbTimer);
            this.wsHbTimer = 0;
        }

        if (this.reconnWebSocketTimer) {
            clearInterval(this.reconnWebSocketTimer);
            this.reconnWebSocketTimer = 0;
        }

        if (this.websocket) {
            this.websocket.onopen = null;
            this.websocket.onmessage = null;
            this.websocket.onclose = null;
            this.websocket.close()
        }
    }

    // websocket打开通知
    wsOnOpen(e) {
        Log.debug("websocket open");
        this.wsHbTimer = setInterval(() => {
            if (this.websocketStatus === 'success' && this.websocket) {
                this.websocket.send('{}');
            }
        }, config.websocket.heartbeatInterval * 1000);
        if (this.reconnWebSocketTimer) {
            clearInterval(this.reconnWebSocketTimer);
            this.reconnWebSocketTimer = 0;
        }

        this.websocketStatus = 'success';
        this.websocketRestartCnt = 0;
    }

    // websocket消息通知
    wsOnMessage(e) {
        let content = {}
        try {
            content = JSON.parse(e?.data || "{}") || {}
        } catch (error) {
            Log.error("parse data failed. ", e);
            return;
        }

        let action = content?.Action;
        let { t } = this.props;
        let { flag, jzMember, jzConfig, jzConference } = this.state;
        Log.info("websocket event message", action, printObject(content));

        function commProc(action) {
            let member = jzMember?.MemberList?.find(m => m?.SipNum === content?.MemberInfo?.SipNum);
            if (member) {
                member.State = content?.MemberInfo?.State;
                member.Mute = content?.MemberInfo?.Mute;
                this.setState({
                    flag: !flag,
                    jzMember: jzMember,
                })
                return;
            } else {
                if (action === "mute" || action === "unmute") {
                    let { meAndCreatorMember } = this.state;
                    let member = meAndCreatorMember?.MemberList?.find(m => m?.SipNum === content?.MemberInfo?.SipNum);
                    if (member) {
                        member.State = content?.MemberInfo?.State;
                        member.Mute = content?.MemberInfo?.Mute;
                        this.setState({
                            flag: !flag,
                            meAndCreatorMember: meAndCreatorMember,
                        })
                        return;
                    }
                }
            }
        }

        switch (action) {
            case "login":
            case "logout": {
                break;
            }
            case "create": {
                setTimeout(() => {
                    message.destroy();
                    message.success({ content: t('notify.message.create') });
                    this.setState({
                        joinPageVisible: false,
                        createPageVisible: false,
                    })
                    this.refreshOnClick();
                }, 1000)
                return;
            }
            case "destroy": {
                this.setState({
                    createExistPageVisible: false,
                    cameraControlPageVisible: false,
                    endPageVisible: false,
                    exitPageVisible: false,
                    zoomInPageVisible: false,
                    screenShareVisible: false,
                }, () => {
                    setTimeout(() => {
                        message.destroy();
                        message.success({ content: t('notify.message.destroy') });
                        this.refreshOnClick();
                        this.setState({
                            screenSelect: undefined,
                        })
                    }, 1000)
                })
                return;
            }
            case "online": {
                let member = jzConfig?.MemberList?.find(m => m?.SipNum === content?.SipNum);
                if (member) {
                    message.success({ content: t('notify.message.online', { member: t(ROLE.description(member?.Role)) }) });
                }
                break;
            }
            case "offline": {
                let member = jzConfig?.MemberList?.find(m => m?.SipNum === content?.SipNum);
                if (member) {
                    message.error({ content: t('notify.message.offline', { member: t(ROLE.description(member?.Role)) }) });
                }
                break;
            }
            case "mute": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    if (!this.messageDebounceTimer) {
                        let member = jzConfig?.MemberList?.find(m => m?.SipNum === content?.MemberInfo?.SipNum);
                        message.success({ content: t('notify.message.mute', { member: member ? t(ROLE.description(member?.Role)) : content?.MemberInfo?.NickName }) });
                    }
                    commProc.bind(this)(action);
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "unmute": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    if (!this.messageDebounceTimer) {
                        let member = jzConfig?.MemberList?.find(m => m?.SipNum === content?.MemberInfo?.SipNum);
                        message.success({ content: t('notify.message.unmute', { member: member ? t(ROLE.description(member?.Role)) : content?.MemberInfo?.NickName }) });
                    }
                    commProc.bind(this)(action);
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "addMember": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    let sipNum = content?.MemberInfo?.SipNum;
                    if (sipNum && this.joinMemberTimer[sipNum]) {
                        clearTimeout(this.joinMemberTimer[sipNum]);
                        delete this.joinMemberTimer[sipNum];
                    }
                    let member = jzConfig?.MemberList?.find(m => m?.SipNum === sipNum);
                    message.success({ key: `join_${sipNum}`, content: t('notify.message.join', { member: member ? t(ROLE.description(member?.Role)) : content?.MemberInfo?.NickName }) });
                    commProc.bind(this)(action);
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "addMemberTimeOut": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    let sipNum = content?.MemberInfo?.SipNum;
                    if (sipNum && this.joinMemberTimer[sipNum]) {
                        clearTimeout(this.joinMemberTimer[sipNum]);
                        delete this.joinMemberTimer[sipNum];
                    }
                    let member = jzConfig?.MemberList?.find(m => m?.SipNum === sipNum);
                    message.error({ key: `join_${sipNum}`, content: t('notify.message.join_timeout', {member: member ? t(ROLE.description(member?.Role)) : content?.MemberInfo?.NickName}) });
                    commProc.bind(this)(action);
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                
                break;
            }
            case "delMember": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    if (!this.messageDebounceTimer) {
                        let member = jzConfig?.MemberList?.find(m => m?.SipNum === content?.MemberInfo?.SipNum);
                        message.success({ content: t('notify.message.leave', { member: member ? t(ROLE.description(member?.Role)) : content?.MemberInfo?.NickName }) });
                    }
                    commProc.bind(this)(action);
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "globalMute":
            case "globalUnmute": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    this.debounceMessage();
                    let mute = action === "globalMute" ? 1 : 0;
                    if (mute) {
                        message.success({ content: t('notify.message.global_mute') });
                    } else {
                        message.success({ content: t('notify.message.global_unmute') });
                    }
                    let { jzConference } = this.state;
                    if (jzConference?.ConfInfo) {
                        jzConference.ConfInfo.Mute = mute
                        this.setState({
                            flag: !flag,
                            jzConference: jzConference,
                        })
                        return;
                    }
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "select": {
                if (jzConference?.ConfInfo?.ConferenceId === content?.ConfInfo?.ConferenceId) {
                    let sipNum = content?.SelectView?.SipNum;
                    let member = jzConfig?.MemberList.find(m => m?.SipNum === sipNum);
                    let info = this.opertionViewRecords[sipNum];
                    if (info) {
                        if (!this.messageDebounceTimer) {
                            let windowDescription = t(WINDOW.description(info.screenSelectWindow), { window: info.screenSelectWindow || "" })
                            let nickName = sipNum === info.member?.SipNum ? t('view.self') : info.member?.NickName;
                            message.success({ key: "select", content: t('notify.message.select', { role: t(ROLE.description(member?.Role)), window: windowDescription, member: nickName }) });
                        }
                        delete this.opertionViewRecords[sipNum];
                        // this.setState({
                        //     screenSelect: undefined,
                        // })
                    } else if (member) {
                        if (!this.messageDebounceTimer) {
                            message.success({ key: "select", content: t('notify.message.select2', { role: t(ROLE.description(member?.Role)) }) });
                        }
                    }
                } else {
                    Log.warn("can't process", action, "current conference info:", printObject(jzConference));
                }
                break;
            }
            case "castApplying": {
                this.setState({
                    screenShareInfo: content?.SessionInfo,
                    screenShareVisible: true,
                })
                break;
            }
            case "castConfirmed": {
                message.success({ key: "screenShare", content: t('notify.message.screen_share.confirmed', {member: content?.SessionInfo?.NickName}) });
                this.setState({
                    screenShareInfo: content?.SessionInfo,
                    screenShareVisible: false,
                })
                break;
            }
            case "castCanceled": {
                message.error({ key: "screenShare", content: t('notify.message.screen_share.canceled', {member: content?.SessionInfo?.NickName}) });
                this.setState({
                    screenShareInfo: content?.SessionInfo,
                    screenShareVisible: false,
                })
                break;
            }
            case "castRefused": {
                message.error({ key: "screenShare", content: t('notify.message.screen_share.refused', {member: content?.SessionInfo?.NickName}) });
                this.setState({
                    screenShareInfo: content?.SessionInfo,
                    screenShareVisible: false,
                })
                break;
            }
            default:
                Log.warn("websocket event default action", action);
                break;
        }

        if (!this.debounce1) {
            this.debounce1 = setTimeout(() => {
                this.refreshOnClick();
                this.debounce1 = 0;
            }, 200)
        }
    }

    // websocket关闭通知
    wsOnClose(e) {
        Log.debug("websocket close", e);

        if (this.wsHbTimer) {
            clearInterval(this.wsHbTimer);
            this.wsHbTimer = 0;
        }
        this.websocketStatus = 'disconnected';

        if (this.websocketRestartCnt === 1 || this.websocketRestartCnt === 59) {
            this.refreshOnClick()
            this.reconnWebSocketTimer = setTimeout(() => {
                this.initWebSocket();
            }, 1000)
        } else if (this.websocketRestartCnt < 60) {
            this.reconnWebSocketTimer = setTimeout(() => {
                this.initWebSocket();
            }, 1000)
        } else {
            this.uninitWebSocket();
            window.goToMenu();
        }
    }

    debounceMessage(duration = 5000) {
        this.messageDebounceTimer = setTimeout(() => {
            this.messageDebounceTimer = 0;
        }, duration);
    }

    checkStatus(jzConfig, t) {
        if (!jzConfig?.MemberList || jzConfig?.MemberList?.length === 0) {
            message.error({ content: t('check.message.noconfig') })
            return false;
        }
        let member = jzConfig?.MemberList?.find(m => m.Role === ROLE.MID);
        if (!member) {
            message.error({content: t('check.message.midnoconfig') })
            return false;
        }

        if (member?.AppState !== STATE.ONLINE) {
            message.error({content: t('check.message.midoffline') })
            return false;
        }

        return true;
    }

    view(screenSelectRole, screenSelectWindow, member) {
        let { jzConfig, jzConference, jzMember, meAndCreatorMember } = this.state;
        let { t } = this.props;

        Log.debug(`view: role:${screenSelectRole}(${t(ROLE.description(screenSelectRole))}) window:${screenSelectWindow}(${t(WINDOW.description(screenSelectWindow), {window: screenSelectWindow || ""})}) -> ${member?.SipNum}(${member?.NickName})`)

        if (jzConference?.ConfInfo?.MiracastOwner?.length > 0) {
            // 如果正在投屏，则不能选流
            message.error({ content: t('operation.view.disable_for_desktopshare') })
            return;
        }
        let views = [];
        let orgMember = jzConfig?.MemberList?.find(m => m?.Role === screenSelectRole);
        if (!orgMember) {
            Log.debug(`not find role: ${screenSelectRole}(${t(ROLE.description(screenSelectRole))})`)
            return;
        } else if (meAndCreatorMember.MemberList?.find(m => m.SipNum === orgMember.SipNum)?.State !== STATE.JOINED) {
            Log.debug(`find role: ${screenSelectRole}(${t(ROLE.description(screenSelectRole))}) not joined`)
            return;
        }

        let orgViews = jzConference?.SelectViews?.find(v => v?.sipNum === orgMember?.SipNum);
        if (orgViews) {
            // 在原来的选流列表中查找，是否有现在被选的成员
            views = orgViews.views;
            let orgView = views?.find(v => v.sipNum === member?.SipNum);
            if (orgView) {
                // 找到了
                // console.log("find", orgView)
                if (orgView.streamId === screenSelectWindow) {
                    // 选的id和之前的相同，没有变化
                    // console.log("do nothing")
                } else {
                    let view1 = views?.find(v => v.streamId === screenSelectWindow);
                    if (view1) {
                        // 交换位置
                        // console.log(`exchange ${orgView.sipNum}:${orgView.streamId} <-> ${view1.sipNum}:${view1.streamId} streamId`);
                        orgView.sipNum = view1.sipNum;
                        view1.sipNum = member?.SipNum;
                    } else {
                        // console.log(`change ${orgView.sipNum}:${orgView.streamId} -> ${orgView.sipNum}:${screenSelectWindow}`)
                        orgView.streamId = screenSelectWindow;
                    }
                }
            } else {
                // 没找到
                let view1 = views?.find(v => v.streamId === screenSelectWindow);
                if (view1) {
                    // 替换那个窗口
                    // console.log(`replace ${view1.sipNum}:${screenSelectWindow} -> ${member?.SipNum}:${screenSelectWindow}`)
                    if (orgMember?.SipNum === member?.SipNum) {
                        if (screenSelectWindow === WINDOW.MAIN) {
                            // 把自己选到了大窗口，查看当前是否有空余的小窗口位，如果有，则把现在大窗口的成员选到小窗口去
                            for (let streamId = 1; streamId < 5; streamId++) {
                                let v = views?.find(v => v.streamId === streamId);
                                if (!v) {
                                    views.push({ sipNum: view1.sipNum, streamId: streamId, definition: 1})
                                    break;
                                }
                            }
                            view1.sipNum = member?.SipNum;
                        } else {
                            // 把自己选到了小窗口，禁止这样操作
                        }

                    } else {
                        view1.sipNum = member?.SipNum;
                    }
                } else {
                    // 新增一个关系
                    // console.log(`new ${member?.SipNum}:${screenSelectWindow}`)
                    views.push({ sipNum: member?.SipNum, streamId: screenSelectWindow, definition: screenSelectWindow ? 1 : 4, })
                }
            }
        } else {
            // 之前没有为此终端选流，需要构造完整的5屏流
            // Log.debug("complete")
            let cnt = 1;
            views.push({ sipNum: member?.SipNum, streamId: 0, definition: 4, })
            for (let m of (jzMember?.MemberList || [])) {
                if (m.State === STATE.JOINED && m.SipNum !== member?.SipNum) {
                    views.push({ sipNum: m?.SipNum, streamId: cnt, definition: 1, })
                    cnt++;
                }
                if (cnt >= 5) {
                    break;
                }
            }
        }

        // 找自己
        let selfIndex = views.findIndex(v => v.sipNum === orgMember?.SipNum);
        let previewFlag = true
        if (selfIndex !== -1) {
            // 找到了
            let v = views[selfIndex];
            if (v.streamId !== 0) {
                // 选流关系中，若自己被选到了小窗口里，则去掉
                views.splice(selfIndex, 1)
            } else {
                // 自己在大窗口
                previewFlag = false;
            }
        } else {
            // 没找到
        }

        // 找摄像头
        function cameraSplice(views, cnt = 5) {
            if (cnt > 0) {
                let cameraIndex = views.findIndex(v => !!jzMember?.MemberList?.find(m => m.SipNum === v.sipNum && m.DeviceType === "CAMERA-28181"));
                if (cameraIndex !== -1) {
                    if (views[cameraIndex].streamId !== 0) {
                        // 选流关系中，若摄像头被选到了小窗口里，则去掉
                        views.splice(cameraIndex, 1)
                    }
                    return cameraSplice(views, cnt - 1)
                }
            }
            
            return views;
        }
        
        views = cameraSplice(views);

        this.setState({
            [`preview-${screenSelectRole}`]: previewFlag
        })

        apiJzMemberView(this.props, jzConference?.ConfInfo?.SipCode, orgMember?.SipNum, views,
            () => {
                Log.debug(`apiJzMemberView success. conference sipNum:${jzConference?.ConfInfo?.SipCode}, member:${orgMember.SipNum}, views:${printObject(views)}`)
                this.opertionViewRecords[orgMember?.SipNum] = { member: member, screenSelectWindow: screenSelectWindow };
            },
            (_, status, statusMsg, req) => {
                Log.error("apiJzMemberView error. ", status, statusMsg, printObject(req)) 
                message.error({ content: t('api.select.error') })
                return true;
            }
        );
    }

    viewExtra(screenSelectRole, screenSelectWindow, member) {
        let { jzConfig, jzConference, jzMember, meAndCreatorMember } = this.state;
        let { t } = this.props;

        Log.debug(`view: role:${screenSelectRole}(${t(ROLE.description(screenSelectRole))}) window:${screenSelectWindow}(${t(WINDOW.description(screenSelectWindow), {window: screenSelectWindow || ""})}) -> ${member?.SipNum}(${member?.NickName})`)

        if (jzConference?.ConfInfo?.MiracastOwner?.length > 0) {
            // 如果正在投屏，则不能选流
            message.error({ content: t('operation.view.disable_for_desktopshare') })
            return;
        }
        let views = {};
        let orgMember = jzConfig?.MemberList?.find(m => m?.Role === screenSelectRole);
        if (!orgMember) {
            Log.debug(`not find role: ${screenSelectRole}(${t(ROLE.description(screenSelectRole))})`)
            return;
        } else if (meAndCreatorMember.MemberList?.find(m => m.SipNum === orgMember.SipNum)?.State !== STATE.JOINED) {
            Log.debug(`find role: ${screenSelectRole}(${t(ROLE.description(screenSelectRole))}) not joined`)
            return;
        }
        let selectViews = jzConference?.SelectViews || [];

        let orgView = selectViews.find(v => v?.sipNum === orgMember?.SipNum);
        if (orgView) {
            if (orgView?.views?.[0]?.sipNum === member.SipNum) {
                // 所选成员和当前一样，不用再选
                Log.debug(`find role: ${screenSelectRole}(${t(ROLE.description(screenSelectRole))}) view member has no different`)
                return;
            } else {
                views[orgView.sipNum] = member.SipNum;
                // 所选成员是否被其他屏选中
                for (let v of selectViews) {
                    if (v?.views?.[0]?.sipNum === member.SipNum) {
                        // 被某个屏选中，则交换当前屏和这个屏的选择
                        views[v.sipNum] = v.sipNum;
                        if (orgView?.views?.[0]?.sipNum === orgMember.SipNum) {
                            // 次屏原来就是看的自己，则要为交换的屏另外选个成员，与会成员不足，无法选出成员的情况，则看自己
                            for (let m of (jzMember?.MemberList || [])) {
                                if (m.State === STATE.JOINED && !selectViews.find(v => v.views?.[0]?.sipNum !== m.SipNum)) {
                                    views[v.sipNum] = m.SipNum;
                                    break;
                                }
                            }
                        } else {
                            views[v.sipNum] = orgView?.views?.[0]?.sipNum;
                        }
                        break;
                    }
                }
            }
        } else {
            // 没有为次屏，则选该成员
            views[orgMember.SipNum] = member.SipNum;
        }

        for (let sipNum in views) {
            apiJzMemberView(this.props, jzConference?.ConfInfo?.SipCode, sipNum, [{ sipNum: views[sipNum], streamId: 0, definition: 4, }],
                () => {
                    Log.debug(`apiJzMemberView success. conference sipNum:${jzConference?.ConfInfo?.SipCode}, member:${orgMember.SipNum}, views:${printObject(views)}`)
                    this.opertionViewRecords[sipNum] = { member: member, screenSelectWindow: screenSelectWindow };
                },
                (_, status, statusMsg, req) => {
                    Log.error("apiJzMemberView error. ", status, statusMsg, printObject(req)) 
                    message.error({ content: t('api.select.error') })
                    return true;
                }
            );
        }
    }

    refreshOnClick(e, tip = false) {
        e && e.stopPropagation();

        let { t } = this.props;
        let { tabActiveKey, zoomInType, zoomInValue } = this.state;
        let time = 0;
        if (tip) {
            message.loading({ key: "refresh", content: t('operation.refresh.doing') });
            time = 1000;
        }
        clearTimeout(this.refreshTimer);
        this.refreshTimer = setTimeout(() => {
            apiJzInfo(this.props, (_, rsp, req) => {
                Log.debug("apiJzInfo success. jzInfo ", printObject(rsp))
                rsp.MemberList = rsp.MemberList?.sort((a, b) => a.Role - b.Role);
                this.setState({
                    jzConfig: rsp,
                    tabActiveKey: tabActiveKey || rsp.MemberList?.[0]?.SipNum,
                    zoomInValue: zoomInType === "screenShare" ? this.screenShareCodeFormatter(rsp.MemberList?.[0]?.CastCode) : zoomInValue,
                    screenSelect: rsp?.RcType === TYPE.EXTRA6 ? `${ROLE.MID}-0` : undefined,
                })
                let element = document.getElementById("name");
                if (element) {
                    element.innerHTML = rsp.Name;
                }

                let element2 = document.getElementById("status");
                if (element2) {
                    if (rsp.LoginCount > 1) {
                        element2.style.display = 'block';
                    } else {
                        element2.style.display = 'none';
                    }
                }
                

                apiJzConference(this.props, (_, rsp1, req) => {
                    Log.debug("apiJzConference success. jzConference ", printObject(rsp1))
                    if (tip) {
                        message.destroy();
                        message.success({ key: "refresh", content: t('operation.refresh.api.success') });
                    }
                    // 找自己
                    let state = {}
                    if (rsp?.MemberList && rsp1?.ConfInfo?.ConferenceId) {
                        rsp.MemberList.forEach(confMember => {
                            let selectViews = (rsp1?.SelectViews || []).find(v => v.sipNum === confMember?.SipNum);
                            let views = selectViews?.views?.find(v => v.sipNum === confMember?.SipNum);
                            state[`preview-${confMember?.Role}`] = views ? (views?.streamId !== 0) : true
                        })
                    } else {
                        ROLE.list().forEach(e => {
                            state[`preview-${e.key}`] = false
                        });
                    }
                    
                    this.setState({
                        ...state,
                        jzConference: rsp1
                    })
                    let testMember = rsp1?.MemberList?.find(m => m.State === STATE.JOINED);
                    if (rsp1?.ConfInfo?.ConferenceId && testMember) {
                        let { jzConfig, paginationInfo } = this.state;
                        let creatorSipNum = rsp1?.ConfInfo?.CreateSipNum;
                        let selfRcId = rsp1?.MemberList[0]?.RcId
                        let meAndCreatorSipNums = jzConfig?.MemberList?.map(m => m.SipNum) || [];
                        let isCreatorSelf = true;
                        if (meAndCreatorSipNums && !meAndCreatorSipNums.find(sipNum => sipNum === creatorSipNum)) {
                            isCreatorSelf = false;
                        }
                        if (isCreatorSelf) {
                            paginationInfo.pagination.filters = [
                                [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '=', selfRcId]]
                            ];
                        } else {
                            paginationInfo.pagination.filters = [
                                [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '=', selfRcId]],
                                [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['SipNum', 'equal', creatorSipNum]],
                            ];
                        }
                        apiJzConferenceMembers(this.props, paginationInfo, (_, rsp3, req3) => {
                            let creator = rsp3?.MemberList?.find(m => m?.SipNum === creatorSipNum);
                            if (!isCreatorSelf && creator?.RcId) {
                                // 创建者不是自己，创建者是个极真
                                Log.debug("the creator is jz:", printObject(creator?.RcInfo))
                                paginationInfo.pagination.filters = [
                                    [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '=', selfRcId]],
                                    [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '=', creator?.RcId]],
                                ];
                                apiJzConferenceMembers(this.props, paginationInfo, (_, rsp4, req4) => {
                                    Log.debug("apiJzConferenceMembers success. meAndCreatorMember ", printObject(rsp4))
                                    this.setState({
                                        meAndCreatorMember: rsp4
                                    })
                                }, (_, status, statusMsg, req) => {
                                    Log.error("apiJzConferenceMembers error. ", status, statusMsg, printObject(req))
                                })
                            } else {
                                Log.debug("apiJzConferenceMembers success. meAndCreatorMember ", printObject(rsp3))
                                this.setState({
                                    meAndCreatorMember: rsp3
                                })
                            }

                            paginationInfo.pagination.page.size = 2000;
                            if (isCreatorSelf) {
                                paginationInfo.pagination.filters = [
                                    [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '!=', selfRcId]],
                                ];
                            } else {
                                if (creator?.RcId) {
                                    paginationInfo.pagination.filters = [
                                        [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '!=', selfRcId], ['RcId', '!=', creator?.RcId]]
                                    ];
                                } else {
                                    paginationInfo.pagination.filters = [
                                        [['ConferenceId', '=', rsp1?.ConfInfo?.ConferenceId], ['RcId', '!=', selfRcId], ['SipNum', 'not equal', creatorSipNum]]
                                    ];
                                }
                            }

                            apiJzConferenceMembers(this.props, paginationInfo, (_, rsp2, req2) => {
                                Log.debug("apiJzConferenceMembers success. jzMember")
                                this.setState({
                                    jzMember: rsp2
                                })
                                
                            }, (_, status, statusMsg, req) => {
                                Log.error("apiJzConferenceMembers error. ", status, statusMsg, printObject(req))
                            })

                        }, (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceMembers error. ", status, statusMsg, printObject(req))
                        })
                    } else {
                        Log.debug("there have no member(s) joined, do nothing ")
    
                        this.setState({
                            jzMember: undefined,
                            meAndCreatorMember: undefined,
                            screenSelect: undefined,
                        })
                    }
                }, (_, status, statusMsg, req) => {
                    Log.error("apiJzConference error. ", status, statusMsg, printObject(req))
                })
            }, (_, status, statusMsg, req) => {
                Log.error("apiJzInfo error. ", status, statusMsg, printObject(req))
            })
    
        }, time)
    }

    createOnClick(e) {
        Log.info("event ui: create on click ")
        let { t } = this.props;
        e && e.stopPropagation();
        if (!this.checkStatus(this.state.jzConfig, t)) {
            return;
        }

        apiJzConferenceCreator(this.props,
            (_, rsp) => {
                Log.debug("apiJzConferenceCreator success. sipNum:", rsp?.ConfInfo?.SipCode)
                if (rsp?.ConfInfo?.ConferenceId) {
                    // 已经创建过会议，并且没有结束
                    this.setState({
                        createExistPageVisible: true,
                        exist: rsp,
                    })
                } else {
                    this.setState({
                        createPageVisible: true,
                    })
                }
            },
            (_, status, statusMsg, req) => {
                // Log.error("apiJzConferenceCreator error. ", status, statusMsg, printObject(req))
                // message.error({ content: t('modal.create_exist_conference.api.error') })

                this.setState({
                    createPageVisible: true,
                })
                return true;
            }
        )
    }

    joinOnClick(e) {
        Log.info("event ui: join on click ")

        e && e.stopPropagation();
        if (!this.checkStatus(this.state.jzConfig, this.props.t)) {
            return;
        }
        this.setState({
            joinPageVisible: true,
        })
    }

    shutdownOnClick(e) {
        Log.info("event ui: shutdown on click ")

        e && e.stopPropagation();
        if (!this.checkStatus(this.state.jzConfig, this.props.t)) {
            return;
        }
        this.setState({
            shutdownPageVisible: true,
        })
    }

    exitOnClick(e) {
        Log.info("event ui: exit on click ")
        e && e.stopPropagation();
        let { jzConfig, jzConference } = this.state;
        let { t } = this.props;
        let member = jzConfig?.MemberList?.find(m => m.Role === ROLE.MID);
        let midMember = jzConference?.MemberList?.find(m => m.SipNum === member?.SipNum);
        
        if (midMember?.IsChairmain) {
            // 是主席，询问是结束会议，还是自己退出
            this.setState({
                endPageVisible: true,
            })

        } else {
            Modal.confirm({
                title: t('modal.exit_conference.title'),
                content: t('modal.exit_conference.content'),
                okText: t('modal.exit_conference.btn.ok'),
                okType: 'danger',
                cancelText: t('modal.exit_conference.btn.cancel'),
                centered: true,
                maskClosable: true,
                onOk: () => {
                    if (jzConference?.ConfInfo?.SipCode) {
                        for (let sipNum in this.joinMemberTimer) {
                            clearTimeout(this.joinMemberTimer[sipNum])
                        }
                        apiJzConferenceExit(this.props, jzConference.ConfInfo.SipCode,
                            () => {
                                Log.debug("apiJzConferenceExit success. sipNum: ", jzConference.ConfInfo.SipCode)
                                this.debounceMessage();
                            },
                            (_, status, statusMsg, req) => {
                                Log.error("apiJzConferenceExit error. ", status, statusMsg, printObject(req))
                                message.error({ content: t('modal.exit_conference.api.error') })
                                return true;
                            }
                        )
                    }
                },
            });
        }
    }

    globalMuteOnClick(e, mute) {
        Log.info("event ui: global mute on click ")

        e && e.stopPropagation();
        let { t } = this.props;
        let { jzConference } = this.state;
        if (jzConference?.ConfInfo?.SipCode) {
            apiJzConferenceGlobalMute(this.props, jzConference.ConfInfo.SipCode, mute,
                () => { 
                    Log.debug(`apiJzConferenceGlobalMute success. sipNum:${jzConference.ConfInfo.SipCode}, mute:${mute}`)
                },
                (_, status, statusMsg, req) => {
                    Log.error("apiJzConferenceGlobalMute error. ", status, statusMsg, printObject(req))

                    let c = mute ? t('api.global_mute.error') : t('api.global_unmute.error');
                    message.error({ content: c })
                    return true;
                }
            )
        }
    }

    cameraSettingOnClick(e) {
        Log.info("event ui: cameraSetting on click ")

        e && e.stopPropagation();
        if (!this.checkStatus(this.state.jzConfig, this.props.t)) {
            return;
        }

        this.setState({
            cameraSettingPageVisible: true,
        })
    }

    cameraListProcess(e, cameraPaginationInfo) {
        let { t } = this.props;
        apiCameraList(this.props, cameraPaginationInfo,
            (_, rsp, req) => {
                this.setState({
                    cameraPageVisible: true,
                    cameras: rsp?.RecordList,
                    cameraTotal: rsp?.TotalNum,
                })
            },
            (_, status, statusMsg, req) => {
                Log.error("apiCameraList error. ", status, statusMsg, printObject(req))
                message.error({ content: t('modal.camera_list.api.list.error') })
                return true;
            }
        )
    }

    conferenceShowHiddenWindowOnClick(e) {
        Log.info("event ui: showHiddenWindow on click ")

        e && e.stopPropagation();
        let { t } = this.props;
        let { jzConfig, jzConference, meAndCreatorMember, hideWindow } = this.state;
        if (jzConference?.ConfInfo?.SipCode) {
            // eslint-disable-next-line no-unused-expressions
            jzConfig?.MemberList?.forEach(m => {
                let member = meAndCreatorMember?.MemberList?.find(m1 => m1.SipNum === m.SipNum && m1.State === STATE.JOINED && m.Role < ROLE.EXTRA_MIN);
                if (member) {
                    apiJzMemberShowOrHiddenWindow(this.props, jzConference.ConfInfo.SipCode, member.SipNum, `ShowOrHideSmallWindows{"hide":${!hideWindow}}`,
                        () => {
                            Log.debug(`apiJzMemberShowOrHiddenWindow success. sipNum:${jzConference.ConfInfo.SipCode}, member:${member.SipNum}`)
                            this.setState({
                                hideWindow: !hideWindow,
                            }, () => {
                                message.success({ key: "show_hidden_window", content: t(!hideWindow ? 'api.hidden_window.success':'api.show_window.success')})
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzMemberShowOrHiddenWindow error. ", status, statusMsg, printObject(req)) 
                            message.error({ key: "show_hidden_window", content: t(!hideWindow ? 'api.hidden_window.error':'api.show_window.error') })
                            return true;
                        }
                    )
                }
            })
        }
    }

    memberHd(action, value, params, callback) {
        Log.info(`event ui: member(in list) on click action: ${action}, value:${printObject(value)}`)
        
        let member = value;
        let { t } = this.props;
        let { jzConference, jzConfig, screenSelect } = this.state;
        let nickName = member?.NickName;
        switch (action) {
            case "join": {
                message.loading({ key: `join_${member?.SipNum}`, content: t('modal.join_conference.status.doing', { member: t(ROLE.description(member?.Role)) }) });
                this.joinMemberTimer[member?.SipNum] = setTimeout(() => {
                    message.error({ key: `join_${member?.SipNum}`, content: t('modal.join_conference.status.timeout', { member: t(ROLE.description(member?.Role)) }) });
                }, 10 * 1000)
                apiJzConferenceJoin(this.props, jzConference?.ConfInfo?.ConferenceCode, member?.SipNum, 
                    (_, rsp, req) => {
                        Log.debug(`apiJzConferenceJoin success. code:${jzConference?.ConfInfo?.ConferenceCode} sipNum:${member?.SipNum}`)
                        this.debounceMessage();
                    },
                    (_, status, statusMsg, req) => {
                        Log.error("apiJzConferenceJoin error. ", status, statusMsg, printObject(req)) 
                        message.error({ content: t('modal.join_conference.api.error') })
                        return true;
                    }
                )
                break;
            }
                
            case "group-kick":
                nickName = member?.RcInfo?.Name;
            // eslint-disable-next-line no-fallthrough
            case "kick": {
                Modal.confirm({
                    title: t('modal.kick_conference.title'),
                    content: t('modal.kick_conference.content', {member: nickName}),
                    okText: t('modal.kick_conference.btn.ok'),
                    okType: 'danger',
                    cancelText: t('modal.kick_conference.btn.cancel'),
                    centered: true,
                    maskClosable: true,
                    onOk: () => {
                        let { jzConference } = this.state;
                        if (jzConference?.ConfInfo?.SipCode) {
                            apiMemberKick(this.props, jzConference?.ConfInfo?.SipCode, member?.SipNum, 
                                (_, rsp, req) => {
                                    Log.debug(`apiMemberKick success. code:${jzConference?.ConfInfo?.ConferenceCode} sipNum:${member?.SipNum}`)
                                },
                                (_, status, statusMsg, req) => {
                                    Log.error("apiMemberKick error. ", status, statusMsg, printObject(req)) 
                                    message.error({ content: t('modal.kick_conference.api.error') })
                                    return true;
                                }
                            )
                        }
                    },
                });
                
                break;
            }
                
            case "mute":
            case "unmute": {
                apiJzMemberMute(this.props, jzConference?.ConfInfo?.SipCode, member.SipNum, action === "mute",
                    () => { 
                        Log.debug(`apiJzMemberMute success. sipNum:${jzConference?.ConfInfo?.SipCode}, member:${member.SipNum}, mute:${action === "mute"}`)
                    },
                    (_, status, statusMsg, req) => {
                        Log.error("apiJzMemberMute error. ", status, statusMsg, printObject(req)) 
                        let c = action === "mute" ? t('api.mute.error') : t('api.unmute.error');
                        message.error({ content: c })
                        return true;
                    }
                );
                break;
            }
                
            case "group-view-self": {
                for (let m of value) {
                    if ((m.Role === ROLE.MID || m.Role === ROLE.LEFT || m.Role === ROLE.RIGHT) && (m.State === STATE.JOINED)) {
                        this.view(m.Role, 0, m);
                    }
                }
                break;
            }
                
            case "group-view": {
                let mid = ROLE.NONE;
                let left = ROLE.NONE;
                let right = ROLE.NONE;
                let viewFun = undefined;
                if (jzConfig.RcType === TYPE.EXTRA6) {
                    viewFun = this.viewExtra;
                    let role = 0;
                    if (screenSelect) {
                        let sps = screenSelect.split("-");
                        if (sps.length >= 2) {
                            role = parseInt(sps[0]);
                        }
                        
                        if (ROLE.MID <= role && role <= ROLE.RIGHT) {
                            mid = ROLE.MID;
                            left = ROLE.LEFT;
                            right = ROLE.RIGHT;
                        } else if (ROLE.EXTRA1 <= role && role <= ROLE.EXTRA3) {
                            mid = ROLE.EXTRA2;
                            left = ROLE.EXTRA1;
                            right = ROLE.EXTRA3;
                        } else if (ROLE.EXTRA4 <= role && role <= ROLE.EXTRA6) {
                            mid = ROLE.EXTRA5;
                            left = ROLE.EXTRA4;
                            right = ROLE.EXTRA6;
                        }
                    }
                } else {
                    viewFun = this.view;
                    mid = ROLE.MID;
                    left = ROLE.LEFT;
                    right = ROLE.RIGHT;
                }
                for (let m of value) {
                    switch (m?.Role) {
                        case ROLE.MID:
                            m.State === STATE.JOINED && viewFun.bind(this)(mid, 0, m);
                            break;
                        case ROLE.RIGHT:
                            m.State === STATE.JOINED && viewFun.bind(this)(left, 0, m);
                            break;
                        case ROLE.LEFT:
                            m.State === STATE.JOINED && viewFun.bind(this)(right, 0, m);
                            break;
                        default:
                            break;
                    }
                }
                break;
            }
            case "view": {
                let { screenSelectRole, screenSelectWindow } = params;
                Log.debug(`selected window: ${screenSelectRole}-${screenSelectWindow}`)
                let viewFun = jzConfig.RcType === TYPE.EXTRA6 ? this.viewExtra : this.view;
                viewFun.bind(this)(screenSelectRole, screenSelectWindow, member);
                break;
            }
            case "show_hidden": {
                let { hide } = params;
                apiJzMemberShowOrHiddenWindow(this.props, jzConference?.ConfInfo?.SipCode, member.SipNum, `ShowOrHideSmallWindows{"hide":${hide}}`,
                    () => {
                        Log.debug(`apiJzMemberShowOrHiddenWindow success. sipNum:${jzConference?.ConfInfo?.SipCode}, member:${member.SipNum}, hide:${hide}`)
                        callback && callback(true)
                        message.success({ key: "show_hidden_window", content: t(hide ? 'api.hidden_window.success' : 'api.show_window.success') })
                    },
                    (_, status, statusMsg, req) => {
                        Log.error("apiJzMemberShowOrHiddenWindow error. ", status, statusMsg, printObject(req))
                        callback && callback(false)
                        message.error({ key: "show_hidden_window", content: t(hide ? 'api.hidden_window.error' : 'api.show_window.error') })
                        return true;
                    }
                );
                break;
            }
                
            case "camera-control": {
                this.setState({
                    camera: member,
                    cameraControlPageVisible: true,
                })
                break;
            }
            default: {
                Log.debug("unsupported action: ", action, member);
                break;
            }
        }
    }

    cameraHd(action, value) {
        Log.info(`event ui: camera(in list) on click action: ${action}, value:${printObject(value)}`)
        let { jzConference } = this.state;
        let { t } = this.props;
        let camera = value;

        switch (action) {
            case 'join':
                apiMemberJoin(this.props, jzConference?.ConfInfo?.SipCode, camera?.SipNum,
                    (_, rsp, req) => {
                        Log.debug(`apiMemberJoin success.`)
                    },
                    (_, status, statusMsg, req) => {
                        Log.error("apiMemberJoin error. ", status, statusMsg, printObject(req)) 
                        message.error({ content: t('modal.camera_list.api.join.error') })
                        return true;
                    }
                )
                break;
            
            case 'kick':
                Modal.confirm({
                    title: t('modal.kick_camera.title'),
                    content: t('modal.kick_camera.content', { member: camera?.NickName }),
                    okText: t('modal.kick_camera.btn.ok'),
                    okType: 'danger',
                    cancelText: t('modal.kick_camera.btn.cancel'),
                    centered: true,
                    maskClosable: true,
                    onOk: () => {
                        if (jzConference?.ConfInfo?.SipCode) {
                            apiMemberKick(this.props, jzConference?.ConfInfo?.SipCode, camera?.SipNum,
                                (_, rsp, req) => {
                                    Log.debug(`apiMemberKick success.`)
                                },
                                (_, status, statusMsg, req) => {
                                    Log.error("apiMemberKick error. ", status, statusMsg, printObject(req))
                                    message.error({ content: t('modal.kick_camera.api.error') })
                                    return true;
                                }
                            )
                        }
                    },
                });
                break;
            default:
                break;
        }
    }


    mkCreatePage() {
        let { t } = this.props;
        let { createPageVisible, confTitle } = this.state;
        let footer = [];

        function create() {
            this.initConference();
            apiJzConferenceCreate(this.props, confTitle,
                (_, rsp, req) => {
                    Log.debug(`apiJzConferenceCreate success. title:${confTitle}`)
                    this.debounceMessage();
                    this.setState({
                        createPageVisible: false,
                        confTitle: undefined,
                    })
                },
                (_, status, statusMsg, req) => {
                    Log.error("apiJzConferenceCreate error. ", status, statusMsg, printObject(req)) 
                    message.error({ content: t('modal.create_conference.api.error') })
                    return true;
                }
            )
        }

        footer.push(<Button type="default" key="cancel" onClick={() => {
            this.setState({
                createPageVisible: false,
            })
        }}>{t('modal.create_conference.btn.cancel')}</Button>)
        footer.push(<Button type="primary" key="create" onClick={(e) => create.bind(this)()}>{t('modal.create_conference.btn.ok')}</Button>)

        return <Modal
            className="page"
            title={t('modal.create_conference.title')}
            width="50%"
            visible={createPageVisible}
            onCancel={() => {
                this.setState({
                    createPageVisible: false,
                })
            }}
            footer={footer}
        >
            <Input
                className="input"
                size="large"
                placeholder={t('modal.create_conference.input.placeholder')}
                value={confTitle}
                autoFocus
                onPressEnter={create.bind(this)}
                onChange={(e) => {
                this.setState({
                    confTitle: e.target.value
                })
            }}/>
        </Modal>
    }

    mkJoinPage() {
        let { t } = this.props;
        let { joinPageVisible, confCode, jzConference } = this.state;
        let footer = [];

        function join(confCode) {
            confCode = confCode ? `${confCode}`.replace(/\D/g, '') : "";
            this.initConference();
            apiJzConferenceJoin(this.props, confCode, undefined,
                (_, rsp, req) => {
                    Log.debug(`apiJzConferenceJoin success. code:${confCode}`)
                    this.debounceMessage();
                    this.setState({
                        joinPageVisible: false,
                        confCode: undefined,
                    })
                },
                (_, status, statusMsg, req) => {
                    Log.error("apiJzConferenceJoin error. ", status, statusMsg, printObject(req))
                    switch (status) {
                        case 2000:
                            message.error({ content: t('modal.join_conference.api.not_found', {conference: confCode}) })
                            break;
                        default:
                            message.error({ content: t('modal.join_conference.api.error') })
                            break;
                    }
                    return true;
                }
            )
        }

        footer.push(<Button type="default" key="cancel" onClick={() => {
            this.setState({
                joinPageVisible: false,
            })
        }}>{t('modal.join_conference.btn.cancel')}</Button>)
        let tipComponent = undefined;
        if (jzConference?.ConfInfo?.ConferenceId) {
            tipComponent = <div className="tip">
                {t('modal.join_conference.check.p1')}
                <span className="hit">{`${jzConference?.ConfInfo?.Title}${t('modal.join_conference.check.p2', {code: jzConference?.ConfInfo?.ConferenceCode})}`}</span>
                {t('modal.join_conference.check.p3')}
            </div>

            footer.push(<Button type="primary" key="join_again" onClick={() => {
                join.bind(this)(jzConference?.ConfInfo?.ConferenceCode)
            }}>{t('modal.join_conference.btn.join_again')}</Button>)
        }
        footer.push(<Button type="primary" key="join" onClick={(e) => join.bind(this)(confCode)}>{t('modal.join_conference.btn.join')}</Button>)

        return <Modal
            className="page"
            title={t('modal.join_conference.title')}
            width="50%"
            visible={joinPageVisible}
            onCancel={() => {
                this.setState({
                    joinPageVisible: false,
                })
            }}
            footer={footer}
        >
            <Input
                className="input"
                size="large"
                placeholder={t('modal.join_conference.input.placeholder')}
                value={confCode}
                autoFocus
                onPressEnter={(e) => join.bind(this)(confCode)}
                onChange={(e) => {
                    this.setState({
                        confCode: e.target.value
                    })
                }} />
            {tipComponent}
        </Modal>
    }

    mkEndPage() {
        let { t } = this.props;
        let { endPageVisible, jzConference } = this.state;
        let footer = [
            <Button type="default" key="cancel" onClick={() => {
                Log.info("event ui: cancel on click ")
                this.setState({
                    endPageVisible: false,
                })
            }}>{t('modal.end_conference.btn.cancel')}</Button>,
            <Button type="primary" key="exit" onClick={(e) => {
                Log.info("event ui: exit on click ")
                if (jzConference?.ConfInfo?.SipCode) {
                    apiJzConferenceExit(this.props, jzConference.ConfInfo.SipCode,
                        () => {
                            Log.debug("apiJzConferenceExit success. sipNum: ", jzConference.ConfInfo.SipCode)
                            this.setState({
                                endPageVisible: false,
                            }, () => {
                                this.debounceMessage();
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceExit error. ", status, statusMsg, printObject(req))
                            message.error({ content: t('modal.end_conference.api.error') })
                            return true;
                        }
                    )
                }
            }}>{t('modal.end_conference.btn.exit')}</Button>,
            <Button type="danger" key="end" onClick={(e) => {
                Log.info("event ui: end on click ")
                if (jzConference?.ConfInfo?.SipCode) {
                    apiJzConferenceEnd(this.props, jzConference.ConfInfo.SipCode,
                        () => {
                            Log.debug("apiJzConferenceEnd success. sipNum:", jzConference.ConfInfo.SipCode)
                            this.setState({
                                endPageVisible: false,
                            }, () => {
                                this.debounceMessage();
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceEnd error. ", status, statusMsg, printObject(req))
                            message.error({ content: t('modal.end_conference.api.error') })
                            return true;
                        }
                    )
                }
            }}>{t('modal.end_conference.btn.end')}</Button>
        ];

        return <Modal
            className="page end-conference-page"
            width="50%"
            centered={true}
            visible={endPageVisible}
            closable={false}
            onCancel={() => {
                this.setState({
                    endPageVisible: false,
                })
            }}
            footer={footer}
        >
            <div className="end-conference-page-body">
                <Icon className="end-conference-page-body-icon" type="question-circle" /><span className="end-conference-page-body-title">{t('modal.end_conference.title')}</span>
                <div className="end-conference-page-body-content">
                    <div>{t('modal.end_conference.content1')}</div>
                    <div>{t('modal.end_conference.content2')}</div>
                    <div>{t('modal.end_conference.content3')}</div>
                </div>
            </div>
            
        </Modal>
    }

    mkCreateExistPage() {

        let { exist, createExistPageVisible } = this.state;
        let { t } = this.props;

        let footer = [
            <Button type="default" key="cancel" onClick={() => {
                Log.info("event ui: cancel on click ")
                this.setState({
                    createExistPageVisible: false,
                })
            }}>{t('modal.create_exist_conference.btn.cancel')}</Button>,
            <Button type="primary" key="join" onClick={(e) => {
                Log.info("event ui: join on click ")
                if (exist?.ConfInfo?.ConferenceCode) {
                    this.initConference();
                    apiJzConferenceJoin(this.props, exist?.ConfInfo?.ConferenceCode, undefined, 
                        () => {
                            Log.debug("apiJzConferenceJoin success. sipNum: ", exist.ConfInfo.SipCode)
                            this.setState({
                                createExistPageVisible: false,
                                exist: undefined,
                            }, () => {
                                this.debounceMessage();
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceJoin error. ", status, statusMsg, printObject(req))
                            message.error({ content: t('modal.create_exist_conference.api.error') })
                            return true;
                        }
                    )
                }
            }}>{t('modal.create_exist_conference.btn.join')}</Button>,
            <Button type="danger" key="end" onClick={(e) => {
                Log.info("event ui: end on click ")
                if (exist?.ConfInfo?.SipCode) {
                    apiJzConferenceEnd(this.props, exist.ConfInfo.SipCode,
                        () => {
                            Log.debug("apiJzConferenceEnd success. sipNum:", exist.ConfInfo.SipCode)
                            this.setState({
                                createExistPageVisible: false,
                                exist: undefined,
                            }, () => {
                                this.debounceMessage();
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceEnd error. ", status, statusMsg, printObject(req))
                            message.error({ content: t('modal.create_exist_conference.api.error') })
                            return true;
                        }
                    )
                }
            }}>{t('modal.create_exist_conference.btn.end')}</Button>,
        ];

        return <Modal
            className="page create-exist-conference-page"
            width="50%"
            centered={true}
            visible={createExistPageVisible}
            closable={false}
            onCancel={() => {
                this.setState({
                    createExistPageVisible: false,
                })
            }}
            footer={footer}
        >
            <div className="create-exist-conference-page-body">
                <Icon className="create-exist-conference-page-body-icon" type="question-circle" /><span className="create-exist-conference-page-body-title">{t('modal.create_exist_conference.title')}</span>
                <div className="create-exist-conference-page-body-content">
                    <div>{t('modal.create_exist_conference.content1')}</div>
                    <div>{t('modal.create_exist_conference.content2')}<span className="danger">{this.codeFormatter(this.codeFix(exist?.ConfInfo?.ConferenceCode))}</span></div>
                    <div>{t('modal.create_exist_conference.content3')}<span>{exist?.ConfInfo?.Title}</span></div>
                </div>
            </div>
            
        </Modal>
    }

    mkExitPage() {
        let { jzConference, exitPageVisible } = this.state;
        let { t } = this.props;

        let footer = [
            <Button type="default" key="cancel" onClick={() => {
                Log.info("event ui: cancel on click ")
                this.setState({
                    exitPageVisible: false,
                })
            }}>{t('modal.end_conference.btn.cancel')}</Button>,
            <Button type="primary" key="exit" onClick={(e) => {
                Log.info("event ui: exit on click ")
                if (jzConference?.ConfInfo?.SipCode) {
                    apiJzConferenceExit(this.props, jzConference.ConfInfo.SipCode,
                        () => {
                            Log.debug("apiJzConferenceExit success. sipNum: ", jzConference.ConfInfo.SipCode)
                            this.setState({
                                exitPageVisible: false,
                            }, () => {
                                this.debounceMessage();
                            })
                        },
                        (_, status, statusMsg, req) => {
                            Log.error("apiJzConferenceExit error. ", status, statusMsg, printObject(req))
                            message.error({ content: t('modal.exit_conference.api.error') })
                            return true;
                        }
                    )
                }
            }}>{t('modal.end_conference.btn.exit')}</Button>,
        ];

        return <Modal
            className="page"
            title={t('modal.exit_conference.title')}
            width="50%"
            centered={true}
            visible={exitPageVisible}
            onCancel={() => {
                this.setState({
                    exitPageVisible: false,
                })
            }}
            footer={footer}
        >
            {t('modal.exit_conference.content')}
        </Modal>

    }

    mkShutdownPage() {
        let { shutdownPageVisible } = this.state;
        let { t } = this.props;

        let footer = [
            <Button type="default" key="cancel" onClick={() => {
                Log.info("event ui: cancel on click ")
                this.setState({
                    shutdownPageVisible: false,
                })
            }}>{t('modal.shutdown.btn.cancel')}</Button>,
            <Button type="danger" key="exit" onClick={(e) => {
                Log.info("event ui: exit on click ")
                apiShutdown(this.props,
                    () => {
                        Log.debug("apiShutdown success. ")
                        this.setState({
                            shutdownPageVisible: false,
                        }, () => {
                            this.debounceMessage();
                        })
                    },
                    (_, status, statusMsg, req) => {
                        Log.error("apiShutdown error. ", status, statusMsg, printObject(req))
                        message.error({ content: t('modal.shutdown.api.error') })
                        return true;
                    }
                )
            }}>{t('modal.shutdown.btn.ok')}</Button>,
        ];

        return <Modal
            className="page"
            title={t('modal.shutdown.title')}
            width="50%"
            centered={true}
            visible={shutdownPageVisible}
            onCancel={() => {
                this.setState({
                    shutdownPageVisible: false,
                })
            }}
            footer={footer}
        >
            {t('modal.shutdown.content')}
        </Modal>

    }

    mkScreenSharePage() {
        let { t } = this.props;
        let { screenShareVisible, screenShareInfo } = this.state;
        let footer = [
            <Button type="danger" key="disagree" onClick={() => {
                Log.info("event ui: disagree on click ")
                apiScreenShareDisagree(this.props, screenShareInfo?.SessionId)
                this.setState({
                    screenShareVisible: false,
                })
            }}>{t('modal.screen_share.btn.disagree')}</Button>,
            <Button type="primary" key="agree" onClick={() => {
                Log.info("event ui: agree on click ")
                apiScreenShareAgree(this.props, screenShareInfo?.SessionId)
                this.setState({
                    screenShareVisible: false,
                })
            }}>{t('modal.screen_share.btn.agree')}</Button>,
        ];
        return <Modal
            className="page"
            title={t('modal.screen_share.title')}
            width="50%"
            centered={true}
            visible={screenShareVisible}
            maskClosable={false}
            onCancel={() => {
                apiScreenShareDisagree(this.props, screenShareInfo?.SessionId)
                this.setState({
                    screenShareVisible: false,
                })
            }}
            footer={footer}
        >
            <div>
                {t('modal.screen_share.content', { member: screenShareInfo?.NickName })}
            </div>
        </Modal>
    }

    mkCameraSettingPage() {
        let { t } = this.props;
        let { jzConfig, cameraSettingPageVisible } = this.state;

        return <Modal
            className="page camera-setting-page"
            title={t('modal.camera_setting.title')}
            width="50%"
            centered={true}
            visible={cameraSettingPageVisible}
            onCancel={() => {
                this.setState({
                    cameraSettingPageVisible: false,
                })
            }}
            footer={null}
        >
            <div className="camera-setting-page-content">
                <div className="camera-setting-page-content-mode">
                    <div className="camera-setting-page-content-mode-title">{t('modal.camera_setting.mode.title')}</div>
                    <Radio.Group buttonStyle="solid" value={jzConfig?.CameraCtrl?.CtrlMode} onChange={(e) => {
                        let mod = e.target.value;
                        Log.info("event ui: camera mod on change", mod)
                        apiCameraModeSet(this.props, mod,
                            () => {
                                Log.debug("apiCameraModeSet success. mod: ", mod)
                            },
                            (_, status, statusMsg, req) => {
                                Log.error("apiCameraModeSet error. ", status, statusMsg, printObject(req))
                                message.error({ content: t('modal.camera_setting.api.error') })
                                return true;
                            }
                        )
                    }}>
                        <Radio.Button value={0}>{t('modal.camera_setting.mode.radio.manual')}</Radio.Button>
                        <Radio.Button value={1}>{t('modal.camera_setting.mode.radio.auto')}</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="camera-setting-page-content-position">
                    <div className="camera-setting-page-content-position-title">{t('modal.camera_setting.position.title')}</div>
                    <Radio.Group buttonStyle="solid" value={jzConfig?.CameraCtrl?.CarmeraPosition} onChange={(e) => {
                        let position = e.target.value;
                        Log.info("event ui: camera position on change", position)
                        apiCameraPositionSet(this.props, position,
                            () => {
                                Log.debug("apiCameraPositionSet success. position: ", position)
                            },
                            (_, status, statusMsg, req) => {
                                Log.error("apiCameraPositionSet error. ", status, statusMsg, printObject(req))
                                message.error({ content: t('modal.camera_setting.api.error') })
                                return true;
                            }
                        )
                    }}>
                        <Radio.Button value={0}>{t('modal.camera_setting.position.radio.sitting')}</Radio.Button>
                        <Radio.Button value={1}>{t('modal.camera_setting.position.radio.standing')}</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
        </Modal>
    }

    mkZoomInPage() {
        let { zoomInPageVisible, zoomInValue, zoomInType } = this.state;
        let { t } = this.props;
        let content = undefined;

        switch (zoomInType) {
            case 'screenShare':
                content = <Fragment>
                    <Button className="zoom-in-page-content-btn" key="refresh" type="link" onClick={() => {
                        apiScreenShareCodeResert(this.props,
                            () => {
                                message.success({ key: "reset", content: t('terminal.screen_share_code.api.reset.success') });
                                this.refreshOnClick();
                            },
                            () => {
                                message.success({ key: "reset", content: t('terminal.screen_share_code.api.reset.error') });
                                this.refreshOnClick();
                                return true;
                            }
                        )
                    }}>
                        <ReloadOutlined className="icon" />{t('terminal.screen_share_code.btn.refresh')}
                    </Button>
                    <div className="zoom-in-page-content-zoom-in">{zoomInValue}</div>
                    <div className="zoom-in-page-content-tips">{t('terminal.screen_share_code.tips')}</div>
                </Fragment>
                break;
        
            default:
                content = <Fragment>
                    <div className="zoom-in-page-content-zoom-in">{zoomInValue}</div>
                </Fragment>
                break;
        }

        return <Modal
            className="page zoom-in-page"
            width="60%"
            centered={true}
            closable={false}
            visible={zoomInPageVisible}
            footer={null}
            onCancel={() => {
                this.setState({
                    zoomInPageVisible: false,
                })
            }}
        >
            <div className="zoom-in-page-content">
                {content}
            </div>
        </Modal>
    }

    mkOperation() {
        let { t } = this.props;
        let { jzConfig, jzConference, hideWindow, cameraPaginationInfo } = this.state;
        let actions = [];
        let member = jzConfig?.MemberList?.find(m => m.Role === ROLE.MID);
        let midMember = jzConference?.MemberList?.find(m => m.SipNum === member?.SipNum);
        let testMember = jzConference?.MemberList?.find(m => m.State === STATE.JOINED);

        if (jzConference?.ConfInfo?.ConferenceId && testMember) {
            // 已经入会
            actions.push(<Button key="exit" className="action-item" type="primary" onClick={(e) => this.exitOnClick(e)}><LogoutOutlined className="icon" />{t('operation.exit.btn')}</Button>)
            actions.push(<Button key="show_hidden_window" className="action-item" type="primary" onClick={(e) => this.conferenceShowHiddenWindowOnClick(e)}>
                {hideWindow ? <EyeOutlined className="icon"/> : <EyeInvisibleOutlined className="icon" />}
                {t(hideWindow ? 'operation.show_window.btn' : 'operation.hide_window.btn')}
            </Button>)

            if (midMember?.IsChairmain) {
                // actions.unshift(<Button key="end" className="action-item" type="primary" onClick={(e) => this.endOnClick(e)}><PoweroffOutlined className="icon"/>{t('operation.end.btn')}</Button>)

                if (!jzConference?.ConfInfo?.Mute) {
                    actions.push(<Button key="mute" className="action-item" type="primary" onClick={(e) => this.globalMuteOnClick(e, true)}><AudioMutedOutlined className="icon"/>{t('operation.global_mute.btn')}</Button>)
                } else {
                    actions.push(<Button key="unmute" className="action-item" type="primary" onClick={(e) => this.globalMuteOnClick(e, false)}><AudioOutlined className="icon"/>{t('operation.global_unmute.btn')}</Button>)
                }
            }
            actions.push(<Button key="camera-setting" className="action-item" type="primary" onClick={(e) => this.cameraSettingOnClick(e, false)}><InstagramOutlined className="icon"/>{t('operation.camera_setting.btn')}</Button>)
            actions.push(<Button key="camera" className="action-item" type="primary" onClick={(e) => {
                Log.info("event ui: cameraList on click ")
                cameraPaginationInfo.pagination.filters = [[['AppState', 'equal', STATE.ONLINE], ['IsIssued', '=', 1]]];
                this.cameraListProcess(e, cameraPaginationInfo)
            }}><AlertOutlined className="icon" />{t('operation.camera_list.btn')}</Button>)
        } else {
            // 未入会
            let disable = member?.AppState !== STATE.ONLINE;
            actions.push(<Button key="create" className="action-item" type={disable ? "danger" : "primary"} onClick={(e) => this.createOnClick(e)}><RocketOutlined className="icon"/>{t('operation.create.btn')}</Button>)
            actions.push(<Button key="join" className="action-item" type={disable ? "danger" : "primary"} onClick={(e) => this.joinOnClick(e)}><UsergroupAddOutlined className="icon" />{t('operation.join.btn')}</Button>)
            actions.push(<Button key="shutdown" className="action-item" type={disable ? "danger" : "primary"} onClick={(e) => this.shutdownOnClick(e)}><PoweroffOutlined className="icon" />{t('operation.shutdown.btn')}</Button>)
            actions.push(<Button key="camera-setting" className="action-item" type={disable ? "danger" : "primary"} onClick={(e) => this.cameraSettingOnClick(e, false)}><InstagramOutlined className="icon" />{t('operation.camera_setting.btn')}</Button>)
        }

        actions.push(<Button key="refresh" className="action-item" type="primary" onClick={(e) => {
            Log.info(`event ui: refresh on click. `)
            message.destroy();
            this.refreshOnClick(e, true);
        }}><ReloadOutlined className="icon" />{t('operation.refresh.btn')}</Button>)

        return <Row className="actions" justify="space-between">
            {actions}
        </Row>
    }

    mkCRoom() {
        let { t } = this.props;
        let { jzConfig, jzConference } = this.state;
        let state = "";
        let stateDesc = "";
        let member = jzConfig?.MemberList?.find(m => m.Role === ROLE.MID);
        let midMember = jzConference?.MemberList?.find(m => m.SipNum === member?.SipNum);
        let testMember = jzConference?.MemberList?.find(m => m.State === STATE.JOINED);
        let flags = undefined;
        if (jzConference?.ConfInfo?.ConferenceId && testMember) {
            // 已经入会
            state = STATE.JOINED;
            stateDesc = t(STATE.description(STATE.INPRORESS));

            flags = [];

            if (midMember?.IsInternalMs) {
                flags.push(<Tag key="lan" className="show" color="blue">{t('tag.lan')}</Tag>);
            } else {
                flags.push(<Tag key="cloud" className="show" color="blue">{t('tag.cloud')}</Tag>);
            }

            flags.push(<Tag key="global_mute" className={jzConference?.ConfInfo?.Mute ? "show" : "hidden"} color="red">{t('tag.global_mute')}</Tag>);
            flags.push(<Tag key="global_unmute" className={jzConference?.ConfInfo?.Mute ? "hidden" : "show"} color="green">{t('tag.global_unmute')}</Tag>);

        } else {
            state = STATE.ONLINE;
            stateDesc = t(STATE.description(STATE.NOT_JOINED));
        }

        let confCode = undefined;
        if (jzConference?.ConfInfo?.ConferenceCode) {
            confCode = this.codeFormatter(this.codeFix(jzConference?.ConfInfo?.ConferenceCode))
        }

        return <Row className="croom">
            <Col className="col" span={24}>
                <Card className="card conference">
                    <Descriptions className="descriptions" column={3}>
                        <Descriptions.Item label={t('croom.conference_id')}><div className="value"
                            onClick={(e) => {
                                if (confCode) {
                                    this.setState({
                                        zoomInPageVisible: true,
                                        zoomInValue: confCode,
                                        zoomInType: 'normal',
                                    })
                                }
                            }}>{confCode || t('common.none')} {confCode ? <SearchOutlined className="conference-id-zoom-in" /> : undefined}</div></Descriptions.Item>
                        <Descriptions.Item label={t('croom.state')}><div className={"state " + state}>{stateDesc}</div></Descriptions.Item>
                        <Descriptions.Item label={t('croom.attr')}><div>{flags || t('common.none')}</div></Descriptions.Item>
                        <Descriptions.Item label={t('croom.title')}>
                            <div className="value" onClick={(e) => {
                                setTimeout(() => {
                                    message.info({ key: "tips", content: jzConference?.ConfInfo?.Title })
                                })
                            }}>{jzConference?.ConfInfo?.Title || t('common.none')}</div>
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="会议SIP号"><div className="value">{jzConference?.ConfInfo?.SipCode || "无"}</div></Descriptions.Item> */}
                        <Descriptions.Item label={t('croom.creator')}><div className="value" onClick={(e) => {
                        setTimeout(() => {
                            message.info({ content: jzConference?.ConfInfo?.CreateUserName })
                        })
                    }}>{jzConference?.ConfInfo?.CreateUserName || t('common.none')}</div></Descriptions.Item>
                        <Descriptions.Item label={t('croom.create_time')}><div className="value">{jzConference?.ConfInfo?.CreateTime || t('common.none')}</div></Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>
        </Row>
    }

    mkMember() {
        let { t } = this.props;
        let { height, width, jzConfig, jzConference, jzMember, meAndCreatorMember, screenSelect, tabActiveKey } = this.state;
        height = height || 0;
        width = width || 0;
        let mkCard = (member, confMember, state, stateDesc, role) => {
            let views = jzConference?.SelectViews?.find(m => m.sipNum === member?.SipNum);
            let tagComponent = [];
            if (state === STATE.JOINED) {
                tagComponent.push(<Tag key="creator" className={(member?.Role === ROLE.MID && confMember?.IsChairmain) ? "show" : "hidden"} color="cyan">{t('tag.creator')}</Tag>);
                tagComponent.push(<Tag key="mute" className={confMember?.Mute ? "show" : "hidden"} color="red">{t('tag.mute')}</Tag>);
            }

            let nickNameMap = {}
            if (views?.views) {
                for (let view of views.views) {
                    nickNameMap[view.streamId] = jzMember?.MemberList?.find(m => m.SipNum === view.sipNum)?.NickName;
                    if (!nickNameMap[view.streamId]) {
                        nickNameMap[view.streamId] = meAndCreatorMember?.MemberList?.find(m => m.SipNum === view.sipNum)?.NickName;
                    }
                }
            }
            let preview = this.state[`preview-${member?.Role}`]
            // console.log(`member.Role: ${member?.Role} preview: ${preview}`);
            if (jzConfig.RcType === TYPE.NORMAL) {
                return <Card id="card" className="card">
                    <Descriptions className="descriptions" column={5}>
                        <Descriptions.Item span={2} label={t('terminal.nickname')}><div className="value" onClick={(e) => {
                            setTimeout(() => {
                                message.info({ content: member?.NickName })
                            })
                        }}>{member?.NickName || t('common.none')}</div></Descriptions.Item>
                        <Descriptions.Item label={t('terminal.state')}><div className={"state " + state}>{stateDesc}</div></Descriptions.Item>
                        <Descriptions.Item label={t('terminal.attr')}><div>{tagComponent.length ? tagComponent : t('common.none')}</div></Descriptions.Item>
                        {(ROLE.MID === role && !jzConference?.ConfInfo?.ConferenceId) ? <Descriptions.Item label={t('terminal.screen_share_code.title')}><div className="value"
                            onClick={(e) => {
                                this.setState({
                                    zoomInPageVisible: true,
                                    zoomInValue: this.screenShareCodeFormatter(member?.CastCode),
                                    zoomInType: 'screenShare'
                                })
                            }}
                        >
                            {this.screenShareCodeFormatter(member?.CastCode) || t('common.none')} {member?.CastCode ? <SearchOutlined className="conference-id-zoom-in" /> : undefined}
                        </div>
                        </Descriptions.Item> : undefined}
                    </Descriptions>
                    <div className="screen">
                        <div className="title">{t('terminal.layout')}</div>
                        <Row >
                            <Col span={24} key={0}>
                                <div id={`${role}-0`} className={"m " + (screenSelect === `${role}-0` ? "selected" : "unselect")} style={{ height: width / 12 }} onClick={(e) => {
                                    e.stopPropagation();
                                    if (state === STATE.JOINED) {
                                        let select = `${role}-0`
                                        Log.info(`event ui: screen on click. select:${select}`)

                                        this.setState({
                                            screenSelect: select
                                        })
                                    }
                                }}>{nickNameMap[0] ? <div className="nickname">{nickNameMap[0]}</div> : t('common.none')}</div>
                            </Col>
                        </Row>
                        <Row justify="center">
                            {
                                Array.from({ length: 4 }).map((value, index) => {
                                    let id = `${role}-${index + 1}`;
                                    return <Col span={preview ? 4 : 5} className="screen-col" style={{ width: preview ? '20%' : '25%' }} key={index}>
                                        <div id={id} className={"l " + (screenSelect === id ? "selected " : "unselect ") + ((index === 3 && !preview) ? "last" : "")} style={{ height: width / 15 }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (state === STATE.JOINED) {
                                                Log.info(`event ui: screen on click. select:${id}`)

                                                this.setState({
                                                    screenSelect: id
                                                })
                                            }
                                        }}>{nickNameMap[index + 1] ? <div className="nickname">{nickNameMap[index + 1]}</div> : t('common.none')}</div>
                                    </Col>
                                })
                            }
                            {
                                <Col span={4} className="screen-col" style={{ width: preview ? '20%' : 0 }}>
                                    <div id="preview" style={{ height: width / 15 }} className={"l unselect " + (preview ? "last" : "gone")} >
                                        {/* {member?.NickName} */}
                                        {preview ? t('view.self') : undefined}
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>
                </Card>
            } else if (jzConfig.RcType === TYPE.EXTRA6) {
                return <Card className="card">
                    <Col span={12}>
                        <Descriptions className="descriptions" column={2}>
                            <Descriptions.Item label={t('terminal.nickname')}><div className="value" onClick={(e) => {
                                setTimeout(() => {
                                    message.info({ content: member?.NickName })
                                })
                            }}>{member?.NickName || t('common.none')}</div></Descriptions.Item>
                            <Descriptions.Item label={t('terminal.state')}><div className={"state " + state}>{stateDesc}</div></Descriptions.Item>
                            <Descriptions.Item label={t('terminal.attr')}><div>{tagComponent.length ? tagComponent : t('common.none')}</div></Descriptions.Item>
                            {(ROLE.MID === role && !jzConference?.ConfInfo?.ConferenceId) ? <Descriptions.Item label={t('terminal.screen_share_code.title')}><div className="value"
                                onClick={(e) => {
                                    this.setState({
                                        zoomInPageVisible: true,
                                        zoomInValue: this.screenShareCodeFormatter(member?.CastCode),
                                        zoomInType: 'screenShare'
                                    })
                                }}
                            >
                                {this.screenShareCodeFormatter(member?.CastCode) || t('common.none')} {member?.CastCode ? <SearchOutlined className="conference-id-zoom-in" /> : undefined}
                            </div>
                            </Descriptions.Item> : undefined}
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <div className="screen-extra">
                            <div className="title">{t('terminal.src')}</div>
                            <div className="m">
                                {nickNameMap[0] ? <div className="nickname">{nickNameMap[0]}</div> : t('common.none')}
                            </div>
                        </div>
                    </Col>
                </Card>
            }

            return undefined;
        }

        let iconState = (m, confMember) => {
            let state = STATE.OFFLINE;
            let stateDesc = t(STATE.description(STATE.OFFLINE));
            let icon = <DisconnectOutlined style={{ color: "#ff4d4f" }} />;
            if (m?.AppState === STATE.ONLINE) {
                state = STATE.ONLINE;
                stateDesc = t(STATE.description(STATE.ONLINE));
                icon = <LinkOutlined style={{ color: "#73d13d" }} />;

                if (meAndCreatorMember?.MemberList?.length > 0) {
                    // 已经入会
                    switch (confMember?.State) {
                        case STATE.JOINED:
                            state = STATE.JOINED;
                            stateDesc = t(STATE.description(STATE.JOINED));
                            icon = <LinkOutlined style={{ color: "#73d13d" }} />;
                            break;
                        case STATE.INVITING:
                            state = STATE.INVITING;
                            stateDesc = t(STATE.description(STATE.INVITING));
                            icon = <LoadingOutlined style={{ color: "#ffc53d" }} />;
                            break;
                        case STATE.NOT_JOINED:
                            state = STATE.NOT_JOINED;
                            stateDesc = t(STATE.description(STATE.NOT_JOINED));
                            icon = <ExclamationCircleOutlined style={{ color: "#ffc53d" }} />;
                            break;
                        default:
                            state = STATE.OFFLINE;
                            stateDesc = t(STATE.description(STATE.OFFLINE));
                            icon = <DisconnectOutlined style={{ color: "#ff4d4f" }} />;
                            break;
                    }
                }
            }

            return { state, stateDesc, icon }
        }

        let mList = jzConfig?.MemberList || [];
        let tabsProps = {};
        let panelComponent = (member) => {
            let confMember = meAndCreatorMember?.MemberList?.find(m1 => m1.SipNum === member?.SipNum)
            let { state, stateDesc, icon } = iconState(member, confMember)
            return member ? <div key={member.SipNum} className={"tabs-bar-panel " + (tabActiveKey === member.SipNum ? "selected" : "")} onClick={(e) => {
                e.stopPropagation();
                this.setState({
                    tabActiveKey: member.SipNum,
                    screenSelect: jzConfig?.RcType === TYPE.EXTRA6 ? `${member.Role}-0` : undefined,
                })
            }}>
                <div>
                    <span>{icon}{t(ROLE.description(member.Role))}</span>
                </div>
            </div> : undefined
        }

        let panelNormalComponent = (m) => {
            if (!m) {
                return undefined;
            }
            let confMember = meAndCreatorMember?.MemberList?.find(m1 => m1.SipNum === m?.SipNum)
            let { state, stateDesc, icon } = iconState(m, confMember)
            return <Tabs.TabPane
                key={m?.SipNum}
                tab={<span>{icon}{t(ROLE.description(m.Role))}</span>}
            >
                {mkCard(m, confMember, state, stateDesc, m.Role)}
            </Tabs.TabPane>
        }

        let tabComponent = (
            <Tabs
                ref={(e) => this.tabs = e}
                tabPosition="top"
                className="member-list"
                defaultActiveKey={mList?.[0]?.SipNum}
                activeKey={tabActiveKey}
                onChange={(activeKey) => {
                    this.setState({
                        tabActiveKey: activeKey,
                    })
                }}
                renderTabBar={(props) => {
                    return <div className={(jzConfig?.RcType === TYPE.EXTRA6 ? "tabs-bar-extra6" : "tabs-bar-normal")}>
                        {
                            jzConfig?.RcType === TYPE.EXTRA6 ? <div className="tabs-bar-extra">
                                {
                                    props.panels.map(panel => {
                                        return panelComponent(mList.find(m => m.SipNum === panel?.key && ROLE.EXTRA_MIN <= m.Role && m.Role <= ROLE.EXTRA_MAX))
                                    })
                                }
                            </div> : undefined
                        }
                        <div className="tabs-bar-main">
                            {panelComponent(mList.find(m => ROLE.LEFT === m.Role))}
                            {panelComponent(mList.find(m => ROLE.MID === m.Role))}
                            {panelComponent(mList.find(m => ROLE.RIGHT === m.Role))}
                        </div>
                        <div className="tabs-bar-footer">
                            <div />
                        </div>
                    </div>;
                }
                }
                {...tabsProps}
            >
                {panelNormalComponent(mList.find(m => ROLE.LEFT === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.MID === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.RIGHT === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA1 === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA2 === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA3 === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA4 === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA5 === m.Role))}
                {panelNormalComponent(mList.find(m => ROLE.EXTRA6 === m.Role))}
            </Tabs>)

        return mList?.length > 0 ? tabComponent : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('terminal.empty')}/>
    }

    mkMemberList() {

        let { jzConference, jzConfig, jzMember, meAndCreatorMember, screenSelect, flag } = this.state;
        let member = jzConfig?.MemberList?.find(m => m.Role === ROLE.MID);
        let midMember = jzConference?.MemberList?.find(m => m.SipNum === member?.SipNum);
        return <MemberList
            screenSelect={screenSelect}
            flag={flag}
            isChairmain={midMember?.IsChairmain}
            jzConference={jzConference}
            jzConfig={jzConfig}
            jzMember={jzMember}
            meAndCreatorMember={meAndCreatorMember}
            memberOnClick={(action, value, params, callback) => this.memberHd(action, value, params, callback)}
        />
    }

    mkCameraList() {
        let { cameraPaginationInfo, cameras, cameraTotal, cameraPageVisible, cameraOnlyOnline, jzMember } = this.state;
        let { t } = this.props;

        return <CameraList
            members={jzMember?.MemberList || []}
            onlyOnline={cameraOnlyOnline}
            cameras={cameras}
            total={cameraTotal}
            page={cameraPaginationInfo.pagination.page}
            visible={cameraPageVisible}
            cancelOnClick={() => {
                this.setState({
                    cameraPageVisible: false,
                })
            }}
            paginationOnChange={(num, size) => {
                Log.info("event ui: camera(in list) pagination on change. ", num, size);
                cameraPaginationInfo.pagination.page.size = size;
                cameraPaginationInfo.pagination.page.number = num;
                this.cameraListProcess(null, cameraPaginationInfo)
            }}
            onlyOnlineOnChange={(e) => {
                Log.info("event ui: camera(in list) only online on click. ", e);

                this.setState({
                    cameraOnlyOnline: e,
                })
                if (e) {
                    cameraPaginationInfo.pagination.filters = [[['AppState', 'equal', STATE.ONLINE], ['IsIssued', '=', 1]]];
                } else {
                    cameraPaginationInfo.pagination.filters = [[['IsIssued', '=', 1]]];
                }
                cameraPaginationInfo.pagination.page.number = 1;
                this.cameraListProcess(null, cameraPaginationInfo)
            }}
            cameraOnClick={(action, value) => this.cameraHd(action, value)}
        />
    }

    mkCameraControl() {
        let { camera, cameraControlPageVisible } = this.state;
        let { t } = this.props;

        return <CameraControl
            camera={camera}
            visible={cameraControlPageVisible}
            cancelOnClick={() => {
                this.setState({
                    cameraControlPageVisible: false,
                })
            }}
            cameraOnClick={(cmd, action, speed) => {
                Log.info("event ui: camera control on click. ", cmd, action, speed);

                switch (cmd) {
                    case "start":
                        apiCameraContrlStart(this.props, camera?.SipNum, action, speed,
                            (_, rsp, req) => {
                                
                            },
                            (_, status, statusMsg, req) => {
                                Log.error("apiCameraContrlStart error. ", status, statusMsg, printObject(req))
                                message.error({ content: t('modal.camera_control.api.start.error') })
                            },
                        )
                        break;
                    case "stop":
                        apiCameraContrlStop(this.props, camera?.SipNum,
                            (_, rsp, req) => {
                                
                            },
                            (_, status, statusMsg, req) => {
                                Log.error("apiCameraContrlStop error. ", status, statusMsg, printObject(req))
                                message.error({ content: t('modal.camera_control.api.stop.error') })
                            },
                        )
                        break;
                    default:
                        break;
                }
            }}
        />
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        this.uninitWebSocket()
    }

    componentDidMount() {
        this.initConference();
        this.refreshOnClick()
        this.websocketRestartCnt = 0;
        this.initWebSocket()
        if (version.environment !== "production") {
            window.jz = this;
        }

        // 用户全屏，拉伸窗口时触发
        adapter.addPageResizeListener.bind(this)();

        // 用户切换tab页签，或者最小化
        adapter.addPageHiddenListener.bind(this)();

        // 出现全局错误时触发，上传错误日志
        adapter.addPageErrorListener.bind(this)();

    }

    render() {
        let cameraListComponent = this.mkCameraList();
        let cameraControlComponent = this.mkCameraControl();
        let createComponent = this.mkCreatePage();
        let createExistComponent = this.mkCreateExistPage();
        let joinComponent = this.mkJoinPage();
        let endComponent = this.mkEndPage();
        let exitComponent = this.mkExitPage();
        let zoomInComponent = this.mkZoomInPage();
        let screenShareComponent = this.mkScreenSharePage();
        let cameraSettingComponent = this.mkCameraSettingPage();
        let shutdownComponent = this.mkShutdownPage();

        let operationComponent = this.mkOperation();
        let croomComponent = this.mkCRoom();
        let memberComponent = this.mkMember();
        let memberListComponent = this.mkMemberList();

        return <div className="main">
            {cameraListComponent}
            {cameraControlComponent}
            {createComponent}
            {createExistComponent}
            {joinComponent}
            {endComponent}
            {exitComponent}
            {zoomInComponent}
            {screenShareComponent}
            {cameraSettingComponent}
            {shutdownComponent}
            <div className="content" onClick={(e) => {
                let { jzConfig } = this.state;
                message.destroy();
                if (jzConfig?.RcType !== TYPE.EXTRA6) {
                    this.setState({
                        screenSelect: undefined,
                    })
                }
            }}>
                <Row >
                    <Col id="operations" span={2}>
                        {operationComponent}
                    </Col>
                    <Col id="base" span={14}>
                        {croomComponent}
                        {memberComponent}
                    </Col>
                    <Col id="list" span={8} onClick={(e) => e.stopPropagation()}>
                        {memberListComponent}
                    </Col>
                </Row>
            </div>
        </div>;
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state),
});


export default connect(
    mapState, 
    null,
    null,
)(MainCT);

