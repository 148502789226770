/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import {actNetRequestPending, 
        actNetRequestFailed, 
        actNetRequestSuccess} from '../../util/netReqRD';

import {sendRequest} from '../../util/net';
import { hoMessage } from '../../util/hoComponent';
import { encrypt } from '../../util/logic';

export const ACT_NET_LOGIN = 'loginRequest';

const ACT_LOGIN_SUCCESS = "ACT_LOGIN_SUCCESS"
const ACT_CLEAN_LOGIN = "ACT_CLEAN_LOGIN"

// functions
const saveLoginInfo = (reqUserInfo) => {
    const loginInfo = {
        reqUserInfo: reqUserInfo, 
    };

    try {
        const binState = JSON.stringify(loginInfo);
        localStorage.setItem('jzcontroller_login_info', binState);

    }catch (err){
        console.log('save store loginInfo failed! err: ' + err);
    }
}

const getSavedLoginInfo = () => {
    try {
        const binState = localStorage.getItem('jzcontroller_login_info');
        if (binState === null){
            return undefined;
        }
        return JSON.parse(binState);

    }catch (err){
        return undefined;
    }
}

// reducer handle
const loginState = {
    reqUserInfo: undefined,
};

export const login_reducer = (state=loginState, action) => {
    switch(action.type){
        case ACT_LOGIN_SUCCESS:
            return {
                ...state, 
                reqUserInfo: action.reqUserInfo
            };

        case ACT_CLEAN_LOGIN:
            return {
                reqUserInfo: undefined,
            };

        default:
            return state;
    }
}

// select handle
export const getLoginUserInfo = (state) => {
    return state.login && state.login.reqUserInfo;
}

// actions handle
// ACT_LOGIN_SUCCESS
export const actLoginSuccess = (reqUserInfo) => {
    
    return {
        type: ACT_LOGIN_SUCCESS, 
        reqUserInfo: reqUserInfo, 
    }
}

export const actLoginClear = () => {
    return {
        type: ACT_CLEAN_LOGIN,
    }
}

// thunk handle
export const loginRequestThunk = (req, successHd = undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(ACT_NET_LOGIN));

    const rsp1 = await sendRequest('/cs/v1/app/reallyClient/security/login', {
        ClientCode: encrypt(req?.ClientCode),
        AuthCode: encrypt(req?.AuthCode), 
    });
    if (rsp1 !== undefined) {
        if (rsp1.Status === undefined ) {
            hoMessage({ type: 'error', msg: '请求出错，请检查网络' })
            return
        } else if (rsp1.Status !== 0){
            dispatch(actNetRequestFailed(ACT_NET_LOGIN));
            switch(rsp1.Status){
                case 1000: // 授权码密码错误
                default:
                    hoMessage({type: 'error', msg: '识别码或授权码错误！'});
                    // hoMessage({type: 'error', msg: '服务端错误！'});
                    return;
            }
        }
    }else{
        dispatch(actNetRequestFailed(ACT_NET_LOGIN));
        return;
    }

    const rsp2 = await sendRequest('/cs/v1/mntn/user/info', {}, {Token: rsp1.Token});
    if (rsp2 !== undefined){
        if (rsp2.Status !== 0){
            dispatch(actNetRequestFailed(ACT_NET_LOGIN));
            switch(rsp2.Status){
                default:
                    hoMessage({type: 'error', msg: '识别码或授权码错误！'});
                    // hoMessage({type: 'error', msg: '服务端错误！'});
                    return;
            }
        }else{
            const login = {
                user: {
                    id: rsp2?.UserId,
                    role: {
                        type: "",
                    },
                    name: rsp2?.NickName,
                    account: req?.ClientCode
                },
                auth: {
                    token: rsp1?.Token,
                    expire: rsp1?.Expire,
                    refreshToken: rsp1?.RefreshToken,
                },
                mqttInfo: rsp2?.mqttInfo,
            }
            dispatch(actLoginSuccess(login));
            saveLoginInfo(login);
            dispatch(actNetRequestSuccess(ACT_NET_LOGIN));

            if (successHd) {
                successHd(login);
            }
        }
    }else{
        dispatch(actNetRequestFailed(ACT_NET_LOGIN));
    }
}

export const loginNoauthHd = (histroy, rspBody) => {
    switch(rspBody.Status){
        case 100008:
        default:
            hoMessage({type: 'error', msg: `Token校验失败，请重新绑定`, duration: 5});
            break;
    }

    histroy.push('/');
}

