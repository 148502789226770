
import { actLoginSuccess } from '../pages/login/loginRD';
import { message } from '../util/message';
import { netRequestThunk2 } from '../util/netReqRD';
import { hoTableTransPaginationInfo } from "../util/tableComponent";

export const ACT_NET_JZ_INFO = 'jzInfo';
export const ACT_NET_JZ_REFRESH = 'jzRefresh';
export const ACT_NET_JZ_CONFERENCE = 'jzConference';
export const ACT_NET_JZ_CONFERENCE_MEMBERS = 'jzConferenceMembers';
export const ACT_NET_JZ_CONFERENCE_CREATE = 'jzConferenceCreate';
export const ACT_NET_JZ_CONFERENCE_JOIN = 'jzConferenceJoin';
export const ACT_NET_JZ_CONFERENCE_EXIT = 'jzConferenceExit';
export const ACT_NET_JZ_CONFERENCE_END = 'jzConferenceEnd';
export const ACT_NET_JZ_CONFERENCE_MUTE = 'jzConferenceMute';
export const ACT_NET_JZ_MEMBER_MUTE = 'jzMemberMute';
export const ACT_NET_JZ_MEMBER_VIEW = 'jzMemberView';
export const ACT_NET_JZ_MEMBER_SHOW_WINDOW = 'jzMemberShowWindow';
export const ACT_NET_CAMERA_LIST = 'cameraList';
export const ACT_NET_CAMERA_CONTROL_START = 'cameraControlStart';
export const ACT_NET_CAMERA_CONTROL_STOP = 'cameraControlStop';
export const ACT_NET_MEMBER_JOIN = 'memberJoin';
export const ACT_NET_MEMBER_KICK = 'memberKick';
export const ACT_NET_AUTO_TEST_AUTH = 'autoTestAuth';
export const ACT_NET_SCREEN_SHARE_AGREE = 'screenShareAgree';
export const ACT_NET_SCREEN_SHARE_DISAGREE = 'screenShareDisagree';
export const ACT_NET_SCREEN_SHARE_CODE_RESET = 'screenShareCodeReset';

export function noAuth(dispatch, orgPath, orgBody, orgHeader, reqUserInfo, routeCode, successFun, errorFun) {
    message.destroy();
    // window.goToMenu();

    // 重新刷新token
    const path = `/cs/v1/app/reallyClient/refreshToken`;
    let body = {
        RefreshToken: reqUserInfo?.auth?.refreshToken,
    }
    dispatch(netRequestThunk2(path, body, {}, ACT_NET_JZ_REFRESH,
        (dispatch, rsp, req) => {
            // 刷新成功
            reqUserInfo.auth = {
                token: rsp.Token,
                expire: rsp.Expire,
                refreshToken: rsp.RefreshToken,
            }
            dispatch(actLoginSuccess(reqUserInfo));

            // 再次发出之前的请求
            let header = Object.assign(orgHeader, {
                Token: reqUserInfo?.auth?.token,
            })
            dispatch(netRequestThunk2(orgPath, orgBody, header, routeCode, successFun, errorFun));
        },
        errorFun
    ));

    return true;
}

export function apiJzLogout(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/logout`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_JZ_INFO, successFun, errorFun, () => noAuth(dispatch, path, undefined, header, reqUserInfo, ACT_NET_JZ_INFO, successFun, errorFun)));
}

export function apiJzInfo(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/detail`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_JZ_INFO, successFun, errorFun, () => noAuth(dispatch, path, undefined, header, reqUserInfo, ACT_NET_JZ_INFO, successFun, errorFun)));
}

export function apiJzConference(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conferences`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_JZ_CONFERENCE, successFun, errorFun, () => noAuth(dispatch, path, undefined, header, reqUserInfo, ACT_NET_JZ_CONFERENCE, successFun, errorFun)));
}

export function apiJzConferenceMembers(props, paginationInfo, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conference/memberList`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    const body = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_MEMBERS, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_MEMBERS, successFun, errorFun)));
}

export function apiJzConferenceCreate(props, title, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conference/action/create`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        ConfSubject: title
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_CREATE, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_CREATE, successFun, errorFun)));
}

export function apiJzConferenceCreator(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/qryCreatorConference`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_JZ_CONFERENCE, successFun, errorFun, () => noAuth(dispatch, path, undefined, header, reqUserInfo, ACT_NET_JZ_CONFERENCE, successFun, errorFun)));
}

export function apiJzConferenceJoin(props, confCode, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conference/action/addMember`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        ConferenceCode: confCode,
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_JOIN, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_JOIN, successFun, errorFun)));
}

export function apiJzConferenceExit(props, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conference/action/delMember`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_EXIT, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_EXIT, successFun, errorFun)));
}

export function apiJzConferenceEnd(props, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    const path = `/cs/v1/app/reallyClient/conference/action/destroy`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_END, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_END, successFun, errorFun)));
}

export function apiJzConferenceGlobalMute(props, sipNum, mute, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/globalMute`;
    if (!mute) {
        path = `/cs/v1/app/reallyClient/conference/action/globalUnmute`;
    }
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_CONFERENCE_MUTE, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_CONFERENCE_MUTE, successFun, errorFun)));
}

export function apiJzMemberMute(props, confSipNum, sipNum, mute, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/mute`;
    if (!mute) {
        path = `/cs/v1/app/reallyClient/conference/action/unmute`;
    }
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: confSipNum,
        SipNum: sipNum
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_MEMBER_MUTE, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_MEMBER_MUTE, successFun, errorFun)));
}

export function apiJzMemberView(props, confSipNum, sipNum, views, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/select`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        ConfId: confSipNum,
        SipNum: sipNum,
        Mode: "FiveSmallWindowsOverFullScreen",
        Views: views.map(view => {return {SipNum: view.sipNum, StreamId: view.streamId, Definition: view.definition}})
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_MEMBER_VIEW, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_MEMBER_VIEW, successFun, errorFun)));
}

export function apiJzMemberShowOrHiddenWindow(props, confSipNum, sipNum, content, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/hideSmall`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: confSipNum,
        SipNum: sipNum,
        Body: content,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_JZ_MEMBER_SHOW_WINDOW, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_JZ_MEMBER_SHOW_WINDOW, successFun, errorFun)));
}

export function apiCameraList(props, paginationInfo, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/camera/list`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    const body = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CAMERA_LIST, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_CAMERA_LIST, successFun, errorFun)));
}

export function apiMemberJoin(props, confCode, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/invite`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: confCode,
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEMBER_JOIN, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_MEMBER_JOIN, successFun, errorFun)));
}

export function apiMemberKick(props, confCode, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/conference/action/kick`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipCode: confCode,
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEMBER_KICK, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_MEMBER_KICK, successFun, errorFun)));
}

export function apiCameraContrlStart(props, sipNum, action, speed, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/camera/ctrl/start`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipNum: sipNum,
        Action: action,
        Speed: speed,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CAMERA_CONTROL_START, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_CAMERA_CONTROL_START, successFun, errorFun)));
}

export function apiCameraContrlStop(props, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/camera/ctrl/stop`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CAMERA_CONTROL_STOP, successFun, errorFun, () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_CAMERA_CONTROL_STOP, successFun, errorFun)));
}

export function apiAutoTestAuth(props, password, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/autoTest/auth`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        Password: password,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_AUTO_TEST_AUTH, successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_AUTO_TEST_AUTH, successFun, errorFun)));
}

export function apiScreenShareAgree(props, sessionId, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/cast/panel/confirm`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SessionId: sessionId,
        IsAgree: true,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_SCREEN_SHARE_AGREE, successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_SCREEN_SHARE_AGREE, successFun, errorFun)));
}

export function apiScreenShareDisagree(props, sessionId, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/cast/panel/confirm`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        SessionId: sessionId,
        IsAgree: false,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_SCREEN_SHARE_DISAGREE, successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_SCREEN_SHARE_DISAGREE, successFun, errorFun)));
}

export function apiScreenShareCodeResert(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/castCode/reset`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_SCREEN_SHARE_CODE_RESET, successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, ACT_NET_SCREEN_SHARE_CODE_RESET, successFun, errorFun)));
}


export function apiCameraModeSet(props, mode, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/ctrl/setCameraMode`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        Mode: mode,
    }
    dispatch(netRequestThunk2(path, body, header, "apiCameraModeSet", successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, "apiCameraModeSet", successFun, errorFun)));
}

export function apiCameraPositionSet(props, position, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/ctrl/setCameraPosition`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let body = {
        Position: position,
    }
    dispatch(netRequestThunk2(path, body, header, "apiCameraPositionSet", successFun, errorFun,
        () => noAuth(dispatch, path, body, header, reqUserInfo, "apiCameraPositionSet", successFun, errorFun)));
}

export function apiShutdown(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/app/reallyClient/ctrl/shutdown`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, "apiShutdown", successFun, errorFun,
        () => noAuth(dispatch, path, undefined, header, reqUserInfo, "apiShutdown", successFun, errorFun)));
}

