/* eslint-disable no-unused-vars */
import { config } from './version';

export const sendRequest = (path, body, headers={}, method='POST') => {
    let request = {
        method: method, 
        // mode: 'no-cors', 
        headers: Object.assign({
            'Accept': '*/*',
            'Content-Type': 'application/json',
        }, headers), 
        body: body ? JSON.stringify(body) : ""
    };
    
    let url = path;
    if (path.indexOf("/cm") !== -1) {
        url = config.host.cm + path;
    } else if (path.indexOf("/cs") !== -1) {
        url = config.host.cs + path;
    }

    return fetch(url, request)
    .then((response) => {
        // console.log("then response", response)
        const contentType = response.headers.get('content-type');
        if (200 <= response.status && response.status <= 299) {
            if (contentType && contentType.indexOf('application/json') !== -1) {
                // console.log("then json", response)
                return response.json().then(json => {
                    json.status = response.status;
                    return Promise.resolve(json);
                })
            }
            // console.log("then text", response)
            return response.text().then(text => {
                return Promise.resolve({status: response.status});
            });
        }else{
            if (contentType && contentType.indexOf('application/json') !== -1) {
                // console.log("then json", response)
                return response.json().then(r => Promise.reject(response));
            }
            // console.log("then text", response)
            return response.text().then(r => Promise.reject(response));
        }
    })
    .then(
        ((json) => {
            // console.info("then then json", json)
            return json;
        }), 
        ((text) => {
            // console.info("then then text", text)
            return text;
        }), 
        ((error) => {
            // console.error("then then error", error)
            return error;
        })
    )
    .catch(
        (error) => {
            // console.error('catch error', error);
            return undefined;
        }
    )
}
