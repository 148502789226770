import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
// import {Switch} from 'react-router';
import {Provider} from 'react-redux';
import AnimatedRouter from 'react-animated-router';

import {configStore, loadStoreState} from './redux/store';

import * as serviceWorker from './serviceWorker';

import './i18n';
import './css/animate.css'; 
import './css/custom.css'; 
import './css/antd.css'; 
// import './css/argon.css'; 

import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import Layout from './layout/layout';
import LoginCT from './pages/login/loginCT';

moment.locale('zh-cn');

const store = configStore(loadStoreState());
const app = (
    <ConfigProvider locale={zh_CN}>
        <Provider store={store}>
            <BrowserRouter>
                <AnimatedRouter>
                    <Route exact path='/' component={LoginCT}/>
                    <Route path='/jzcontroller' component={Layout}/>
                </AnimatedRouter>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
