/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'

import MainCT from '../pages/main/mainCT';

class MainRoute extends Component {

    constructor(props){
        super(props);
    }


    componentDidMount() {

    }

    render(){
        return (
            <Switch>                
                <Route path={`${this.props.match.path}/main`}  component={MainCT} />
            </Switch>
        )
    }
}

export default connect(
    null, 
    null
)(MainRoute);