/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Card, Input, Button, Form, Icon, message, Select, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next'

import { loginRequestThunk, ACT_NET_LOGIN } from './loginRD';
import { getNetRequestStatus, actNetRequestClear } from '../../util/netReqRD';
import { LockOutlined, BarcodeOutlined, SmileTwoTone } from '@ant-design/icons';
import { config, switchEnvironment, version } from '../../util/version';
import { Log } from '../../util/log';
import { printObject } from '../../util/logic';

import './less/antMotionStyle.less';
import './login.css'

@withTranslation('translation', {withRef: true})
class LoginCT extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            rememberMe: true,
            definition: false,
            username: '', 
            password: '',
            defaultLoginTo: '/jzcontroller/main',
            evnSwitchVisiable: false,
            flag: false,
        };
    }

    submitOnClick(e){
        e?.preventDefault && e.preventDefault()
        let { defaultLoginTo, username, password, rememberMe } = this.state;
        let { t, history, dispatch } = this.props;

        let body = {
            ClientCode: username,
            AuthCode: password, 
        };
        dispatch(loginRequestThunk(body, (reqUserInfo) => {
            message.destroy();
            message.open({ content: t("login.message.welcome"), duration: 5, icon: <SmileTwoTone /> });
            if (rememberMe) {
                if (window.localStorage && username && password) {
                    Log.info("save the account");
                    window.localStorage.setItem("username", username);
                    window.localStorage.setItem("password", password);
                    window.localStorage.setItem("rememberMe", rememberMe);
                    window.localStorage.setItem("environment", version.environment);
                }
            } else {
                if (window.localStorage) {
                    Log.info("clear the account");
                    window.localStorage.removeItem("username");
                    window.localStorage.removeItem("password");
                    window.localStorage.removeItem("rememberMe");
                    window.localStorage.removeItem("environment");
                }
            }
            Log.info("login success", printObject(reqUserInfo));
            history.push(defaultLoginTo);
        }));
    }

    mkElement1() {
        let { t } = this.props;
        let { username, password, evnSwitchVisiable, rememberMe, flag } = this.state;

        let titleComponent = (
            <div className="banner2-text-wrapper">
                <div id="banner2-title" key="banner2-title" className="banner2-title">{t('login.title')}</div>
            </div>
        );

        let usernameComponent = (
            <Form.Item
                name="username"
                // rules={[{ required: true, message: '请输入极真识别码'}]}
                >
                <div className="username">
                    <div className="title">{t('login.username.title')}</div>
                    <Input 
                        ref={(input) => this.loginUserNameDOM = input} 
                        value={username} 
                        size="large" 
                        placeholder={t('login.username.placeholder')}
                        prefix={<BarcodeOutlined />} 
                        onFocus={(e) => {
                            document.getElementById("home-page").style.padding = '0px 24px';
                            document.getElementById("control").style.marginTop = '0';
                        }
                        }
                        onBlur={(e) => {
                            document.getElementById("home-page").style.padding = '72px 24px';
                            document.getElementById("control").style.marginTop = '5%';
                        }}
                        onChange={(e) => {
                            this.setState({
                                username: e.target.value,
                            });
                        }} 
                        onPressEnter={(e) => this.loginPasswordDOM.focus()}
                    />
                </div>
            </Form.Item>
        )

        let passwordComponent = (
            <Form.Item
                name="password"
                // rules={[{ required: true, message: "请输入授权码"}]}
                >
                <div className="password">
                    <div className="title">{t('login.password.title')}</div>
                    <Input.Password 
                        ref={(input) => this.loginPasswordDOM = input} 
                        autoComplete="on" 
                        value={password} 
                        size="large" 
                        placeholder={t('login.password.placeholder')}
                        prefix={<LockOutlined />} 
                        onFocus={(e) => {
                            document.getElementById("home-page").style.padding = '0px 24px';
                            document.getElementById("control").style.marginTop = '0';
                        }
                        }
                        onBlur={(e) => {
                            document.getElementById("home-page").style.padding = '72px 24px';
                            document.getElementById("control").style.marginTop = '5%';
                        }}
                        onChange={(e) => {
                            this.setState({
                                password: e.target.value,
                            });
                        }} 
                        onPressEnter={(e) => this.submitOnClick(e)}
                    />
                </div>
            </Form.Item>
        )

        let evnComponent = (
            <Form.Item name="env" >
                <div className={"env " + (evnSwitchVisiable ? "show" :"hidden")}>
                    <div className="title">{t('login.server.title')}</div>
                    <Select
                        dropdownClassName="env-select"
                        size="large"
                        value={version.environment} 
                        onChange={(e) => {
                            switchEnvironment(e)
                            this.setState({flag: !flag})
                        }} 
                    >
                        <Select.Option value="production">{t('login.server.production')}</Select.Option>
                        <Select.Option value="show">{t('login.server.show')}</Select.Option>
                        <Select.Option value="test">{t('login.server.test')}</Select.Option>
                    </Select>
                </div>
            </Form.Item>
        )

        let saveAccountComponent = (
            <Form.Item>
                <div className="remember">
                    <Checkbox checked={rememberMe} onChange={(e) => {
                        Log.info("event ui: remember me on click", e.target.checked)
                        this.setState({
                            rememberMe: e.target.checked
                        })
                    }}>{t('login.remember_me.title')}</Checkbox>
                </div>
            </Form.Item>
        )

        let buttonComponent = (
            <Form.Item>
                <div className="login">
                    <Button type="primary" size="large" block onClick={(e) => this.submitOnClick(e)}>{t('login.btn.start')}</Button>
                    <Button type="danger" size="large" block onClick={(e) => {
                        window.WebMsgBridge?.msgToNative && window.WebMsgBridge.msgToNative("{msgType: \"exitApp\"}")
                    }}>{t('login.btn.exit')}</Button>
                </div>
            </Form.Item>
        )

        let inputComponent = (
            <div className="banner2-text-wrapper">
                <div id="control" key="control" className="control">
                    <Card 
                        bordered={false} 
                        >
                        <Form name="basic" className="content">
                            {usernameComponent}
                            {passwordComponent}
                            {evnComponent}
                            {saveAccountComponent}
                            {buttonComponent}
                        </Form>
                    </Card>
                </div>
            </div>
        )

        let element1 = (
            <div id="home-page" className="home-page banner2-page">
                <Row className="element">
                    <Col span={24}>
                        <Row>{titleComponent}</Row>
                        <Row>{inputComponent}</Row>
                    </Col>
                </Row>
            </div>
        );

        return element1;
    }

    componentDidMount() {
        // 窗口大小变化
        function windowSizeOnChange() {
            this.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            });
        }
        window.addEventListener("resize", windowSizeOnChange.bind(this));
        windowSizeOnChange.bind(this)();
        this.setState({
            username: window.localStorage?.getItem("username"),
            password: window.localStorage?.getItem("password"),
            rememberMe: window.localStorage?.getItem("rememberMe") !== "false",
        })

        // 获取url中是否携带env参数
        let reg = new RegExp(`(^|&)env=([^&]*)(&|$)`);
        let r = window.location.search.substr(1).match(reg);
        let env = (r != null) ? decodeURIComponent(r[2]) : window.localStorage?.getItem("environment");
        switchEnvironment(env || "show")
    }

    componentWillUnmount(){
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        let { height, width, definition, evnSwitchVisiable } = this.state;
        let elementComponent1 = this.mkElement1();
        return (
            <div className="templates-wrapper login" style={{ height: height, width: width }}>
                <div style={{position: 'absolute', zIndex: 9999, opacity: definition ? 1 : 0, color: "#fff", padding: 4}}>{width}*{height}</div>
                <div className="banner2">
                    <div className="bg bg0"/>
                    <div className="version" onClick={(e) => this.setState({definition: !definition, evnSwitchVisiable: !evnSwitchVisiable})}>{version.software}</div>
                    {/* <div className="footer">深圳陆地桥科技有限公司 copyright©2021</div> */}
                    {elementComponent1}
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({
    loginStatus: getNetRequestStatus(state, ACT_NET_LOGIN), 
});

export default connect(
  mapState, 
  null
)(LoginCT);
